export default {
  APP_USR: 'Delivery-Take-Away',
  MENU_STATUS : {
    New: '1',
    Recommend: '2',
    Season: '3',
    Vegetarian: '4',
    BestSeller: '5'
  },
  NUMBER_OF_MENU_IN_1_ROW: 3,
  PPOSNO: '001',
  POSNO: '001',
  TOPPING_LIMIT: 1,
  VAT: 10,
  SYSTEM_USER: '41',
  STATUS_ORDER_START: 'orderStart',
  STATUS_ORDERING: 'ordering',
  STATUS_ORDER_SENT: 'orderSent',
  STATUS_ACCEPTED: 'accepted',
  STATUS_COOKING: 'cooking',
  STATUS_READY: 'ready',
  STATUS_COMPLETE: 'completed',
  MONEY_TYPE_CASH:'0',
  MONEY_TYPE_MOMO:'1',
  MONEY_TYPE_PAYOO:'2',
  MINUTES_RELOAD: 1,
  CODE_RESPONSE: {
    SUCCESS: 200,
    FOR_BIDDEN: 403,
    NOT_FOUND: 404,
    BAD_REQUEST: 500
  },
  POS_CONSTANT: {
    MEISAIKB: {
      MENU: '0',
      TOPPING_MANDATORY: '2',
      TOPPING_OPTIONAL: '6'
    }
  },
  TOKEN_PRO_MAX: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjMzMjQ1MTIyLCJqdGkiOiIxODE0ZTkyZjU5MjI0NjJmOGIyMjQwMWQ4NTZjYWY1NyIsInVzZXJfaWQiOjZ9.yss4WxKnS6GXox7kFpMS1vkbmw8R7bECz3SrlAg232M',
  CUSTOMER_ID_REAL_FAKE_PRO_MAX: 6,
  PROMOTION : {
    BY: 'trade_by',
    PREFIX_CUSTOMER: 'cus_',
    DISCOUNT: {
      PERCENT: 'percent_discount',
      MONEY: 'money_discount',
      CASH_VOUCHER: 'cash_voucher',
      FREE_POINT: 'free_point',
    },
    ON: {
      BILL: 'bill',
      ITEM: 'item',
      EVENT: 'event',
      FEESHIP: 'feeship',
    },
    LITERAL_TYPE: {
      ITEM_DISCOUNT: 'item_discount',
      BILL_DISCOUNT: 'bill_discount',
      VOUCHER: 'voucher',
      FREE_POINT: 'free_point'
    }
  },
}
