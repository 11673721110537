<template lang="pug">
section.page-full-height
  .mx-0.row
    div(class="col-12 main__menu")
      .w-100.mx-0.row.pt-4
        .col-4.px-0
          img.food-image(:src="food.image")
        .col-8.pr-0
          h4.text-left.mb-2
            b {{ food.menunm }}
          h6.text-left.mb-3 {{ food.price | formatCurrency }}
          .quantity-control-menu.d-flex
            .quantity-nav
              b-btn.btn-quantity(:disabled="!isLoaded", @click="menuMinus()") -
              span.quantity {{ quantity }}
              b-btn.btn-quantity(:disabled="!isLoaded", @click="menuPlus()") +
          h6.text-left.my-3 {{ (food.priceWithTopping * quantity) | formatCurrency }}

            | {{ $t("menuToppingOption.toppingIncluded") }}
          .pt-3.d-none.d-sm-block
            hr
            b-btn(:disabled="!isLoaded", variant="secondary", @click="cancelOrder") {{ $t("menuToppingOption.btnBackToMainMenu") }}
            b-btn.ml-3(:disabled="!isLoaded", variant="primary", @click="addToCart()") {{ $t("menuToppingOption.btnAddOrder") }}
        .col-12.px-0.d-block.d-sm-none
          hr
          b-btn(:disabled="!isLoaded", variant="secondary", @click="cancelOrder") {{ $t("menuToppingOption.btnBackToMainMenu") }}
          b-btn.fa-pull-right(:disabled="!isLoaded", variant="primary", @click="addToCart()") {{ $t("menuToppingOption.btnAddOrder") }}
      br
      .row(v-show="topping.mandatory.length > 0", v-if="isLoaded")
        b-col
          b-row.text-left.row-cus-lv1
            h5
              b {{ $t("menuToppingOption.mandatoryToppings") }}
          div(v-for="(item, index) in topping.mandatory", :key="index")
            b-row.text-left.group-name.row-cus-lv2
              b {{ item.group_name }}
            b-row.row-cus-lv3
              b-col.text-left(v-for="(top, ind) in item.items", :key="ind", md="6")
                input(:id="top.menucd + index", v-model="chosenMandatoryToppings[index]", type="radio", :value="top", @click="toppingMandatorySelect(top, index)")
                label(:for="top.menucd + index") {{ top.menunm }}
                  | ({{ top.price | formatCurrency }})
      .row.w-100.mx-0(v-show="topping.option.length > 0", v-if="isLoaded")
        .col-12.px-0.text-left
          h5
            b {{ $t("menuToppingOption.optionalToppings") }}
        .w-100(v-for="(item, index) in topping.option", :key="index")
          .text-left.pl-3
            b {{ item.group_name }}
          .row.w-100.mx-0.mt-3
            .topping-input.text-left.col-12.pr-0(v-for="(top, ind) in item.items", :key="ind")
              .input-group
                .quantity-control-topping
                  b-btn.btn-quantity(@click="toppingMinus(top)") -
                  span.quantity {{ top.quantity }}
                  b-btn.btn-quantity(:disabled="top.quantity >= 1", @click="toppingPlus(top, index)") +
                p {{ top.menunm }}
                span.topping-price (
                  | {{ top.price | formatCurrency }}
                  | )
      h5.text-center(v-else) Loading Toppings...
      br
      b-row
    cart-info
  Loader(v-show="isProcessing")

</template>
<script>
import Constants from '@/common/constant';
import OrderAPI from '@/api/order';
import CartInfo from '@/components/common/CartInfo.vue';
import { mapGetters } from 'vuex';
import commonFunc from '../common/commonFunc';
import Loader from '@/components/Loader';

export default {
  name: 'MenuToppingOption',
  components: {
    CartInfo,
    Loader,
  },
  data() {
    return {
      isLoaded: false,
      isProcessing: false,
      food: {},
      amount: null,
      quantity: 0,
      topping: null,
      chosenOptionalToppings: [],
      chosenMandatoryToppings: [],
      parentVAT: 0,
      totalVAT: 0,
      toppingVAT: 0,
      tax_rate: 0,
    };
  },

  computed: {
    totalPrice() {
      return this.food.priceWithTopping * this.quantity;
    },
    ...mapGetters([
      'cartCount',
      'myCartInfo',
      'currentDeliveryId',
      'language',
      'selectedMenu',
    ]),
  },

  watch: {
    language: function (val) {
      this.getFoodDetail();
    },
  },
  created() {
    if (!this.currentDeliveryId) {
      this.$router.push('/');
    }
    this.getFoodDetail();
  },

  mounted() {
    this.$root.$on('reloadMenu', (name) => {
      this.isLoaded = false;
      this.getFoodDetail();
    });
  },

  methods: {
    /**
     * Get food detail with topping
     */
    getFoodDetail() {
      this.isProcessing = true;

      Object.assign(this.food, this.selectedMenu);
      this.food.priceWithTopping = this.food.price;

      this.food.priceNonVATWithTopping = commonFunc.getPriceNonVAT(
        this.food.price,
        this.food.stuckb,
        this.food.tax_rate,
      );

      this.totalVAT = commonFunc.getOnlyVAT(
        this.food.price,
        this.food.stuckb,
        this.food.tax_rate,
      );

      this.parentVAT = this.totalVAT;
      this.quantity = parseInt(this.food.quantity, 10);
      const dataPos = {
        menucd: this.food.menucd,
        lang: this.language,
        brand_id:  this.myCartInfo.brandId,
        storecd: this.myCartInfo.storeCode,
      };

      OrderAPI.getDetailFoodWithTopping(dataPos)
        .then(({ data }) => {
          this.topping = data.data.topping;

          const food = data.data.food;
          this.food.description = food.description;
          this.food.menunm = food.menunm;
          this.food.tax_rate = food.tax_rate

          this.initMandatoryToppings();
          this.isLoaded = true;
          this.isProcessing = false;

          window.scrollTo(0,0);
        })
        .catch((err) => {
          console.log(err)
        });
    },

    initMandatoryToppings() {
      const mandatoryLength = this.topping.mandatory.length;
      if (mandatoryLength === 0) return;

      this.topping.mandatory.forEach((m) => {
        m.items.map((x) => {
          x.meisaikb = Constants.POS_CONSTANT.MEISAIKB.TOPPING_MANDATORY;
          x.smenucd = '1';
          x.quantity = 1;
          x.POSquantity = 1;
          x.tankak = x.price;
          x.tankan = commonFunc.getPriceNonVAT(x.price, x.stuckb, x.tax_rate);
          x.kingakuk = x.tankak;
          x.kingakun = x.tankan;
          x.vat = commonFunc.getOnlyVAT(x.price, x.stuckb, x.tax_rate);
        });
      });

      this.chosenMandatoryToppings = new Array(mandatoryLength);
      for (let i = 0; i < mandatoryLength; i++) {
        // At each mandatory group, select the 1st mandatory topping as default.
        // This includes adding selected MandatoryTopping[Price/VAT] to Total[Price/VAT]
        let top = this.topping.mandatory[i].items[0];
        this.chosenMandatoryToppings[i] = top;
        this.totalVAT += top.vat;
        this.toppingVAT += top.vat;
        this.food.priceWithTopping += top.price;
        this.food.priceNonVATWithTopping += commonFunc.getPriceNonVAT(
          top.price,
          top.stuckb,
          top.tax_rate,
        );
      }
    },

    menuPlus() {
      if (!this.isLoaded) return;
      this.quantity++;
      this.totalVAT += this.parentVAT;
      this.totalVAT += this.toppingVAT;
      this.setPOSTopping();
    },

    menuMinus() {
      if (!this.isLoaded) return;
      if (this.quantity === 1) return;
      this.quantity--;
      this.totalVAT -= this.parentVAT;
      this.totalVAT -= this.toppingVAT;
      this.setPOSTopping();
    },

    /**
     * Set topping quantity and price for POS.
     * Example:
     * Order 3 Pizza, each pizza has 1 topping A, 1 topping B
     *    [Delivery display data]
     *      - Pizza quantity x 3
     *          - Topping A x 1
     *          - Topping B x 1
     *    [POS display data]
     *      - Main menu quantity x 3
     *      - Topping A x 3
     *      - Topping B x 3
     */
    setPOSTopping() {
      let self = this;
      if (this.chosenOptionalToppings.length > 0) {
        this.chosenOptionalToppings.map((x) => {
          x.POSquantity = x.quantity * self.quantity;
          x.kingakuk = x.tankak * x.POSquantity;
          x.kingakun = x.tankan * x.POSquantity;
        });
      }
      if (this.chosenMandatoryToppings) {
        this.chosenMandatoryToppings.map((x) => {
          x.POSquantity = x.quantity * self.quantity;
          x.kingakuk = x.tankak * x.POSquantity;
          x.kingakun = x.tankan * x.POSquantity;
        });
      }
    },

    /**
     * Set mandatory topping then Update total price/vat:
     * 1. Unselect price/vat of previous mandatory topping
     * 2. Select current price/vat of current mandatory topping
     */
    toppingMandatorySelect(top, groupIndex) {
      top.POSquantity = this.quantity;
      top.kingakuk = top.tankak * this.quantity;
      top.kingakun = top.tankan * this.quantity;
      this.toppingVAT -= this.chosenMandatoryToppings[groupIndex].vat;
      this.totalVAT -=
                this.chosenMandatoryToppings[groupIndex].vat *
                this.chosenMandatoryToppings[groupIndex].POSquantity;
      this.toppingVAT += top.vat;
      this.totalVAT += top.vat * top.POSquantity;

      this.food.priceWithTopping -= this.chosenMandatoryToppings[
        groupIndex
      ].price;
      this.food.priceWithTopping += top.price;

      this.food.priceNonVATWithTopping -= commonFunc.getPriceNonVAT(
        this.chosenMandatoryToppings[groupIndex].price,
        this.chosenMandatoryToppings[groupIndex].stuckb,
        this.chosenMandatoryToppings[groupIndex].tax_rate,
      );
      this.food.priceNonVATWithTopping += commonFunc.getPriceNonVAT(
        parseInt(top.price, 10),
        top.stuckb,
        top.tax_rate,
      );
    },

    /**
     * Increase quantity of a topping by 1. Update total price/vat.
     */
    toppingPlus(top, index) {
      if (top.quantity === Constants.TOPPING_LIMIT) return;
      top.quantity++;
      top.POSquantity = top.quantity * this.quantity;

      // const topping = this.topping.option[index].items.filter(x => x.menucd === top.menucd);
      // topping[0].quantity++;

      if (top.quantity === 1) {
        top.tankak = top.price;
        top.tankan = commonFunc.getPriceNonVAT(
          parseInt(top.price),
          top.stuckb,
          top.tax_rate,
        );
        top.kingakuk = top.price;
        top.kingakun = top.tankan;
        top.meisaikb = Constants.POS_CONSTANT.MEISAIKB.TOPPING_OPTIONAL;
        top.smenucd = '1';
        this.chosenOptionalToppings.push(top);
      } else {
        // top.kingakuk += top.price;
        // top.kingakun += top.tankan;
      }

      this.food.priceWithTopping += top.price;
      this.food.priceNonVATWithTopping += commonFunc.getPriceNonVAT(
        top.price,
        top.stuckb,
        top.tax_rate,
      );
      // this.setPOSTopping();
      const vat = commonFunc.getOnlyVAT(top.price, top.stuckb, top.tax_rate);
      this.toppingVAT += vat;
      this.totalVAT += vat * top.POSquantity;
    },

    /**
     * Decrease quantity of a topping by 1. Update total price/vat.
     */
    toppingMinus(top) {
      if (top.quantity === 0) return;

      // When decreasing from 1 -> 0, remove topping from chosen array.
      if (top.quantity === 1) {
        const topping = this.chosenOptionalToppings.find(
          (x) => x.menucd === top.menucd
        );
        const index = this.chosenOptionalToppings.indexOf(topping);
        this.chosenOptionalToppings.splice(index, 1);
      } else {
        // top.kingakuk += top.price;
        // top.kingakun += top.tankan;
      }

      const vat = commonFunc.getOnlyVAT(top.price, top.stuckb, top.tax_rate);
      this.toppingVAT -= vat;
      this.totalVAT -= vat * top.POSquantity;
      top.quantity--;
      top.POSquantity = top.quantity * this.quantity;
      this.food.priceWithTopping -= top.price;
      this.food.priceNonVATWithTopping -= commonFunc.getPriceNonVAT(
        parseInt(top.price),
        top.stuckb,
        top.tax_rate,
      );
    },

    /**
     * Cancel order
     */
    cancelOrder() {
      this.$store.commit('selectedMenuForDetailScreen', null);
      this.$router.push('/main-menu').catch(() => {});
      this.isLoaded = false;
      this.clearMenu();
    },

    /**
         * Add order
         */
    addToCart() {
      if (!this.isLoaded) return;
      this.loading = true;
      // Generate unique menuid by appending all selected toppings
      let generatedId = this.food.menucd;
      // Append mandatory topping menucd
      if (this.chosenMandatoryToppings) {
        if (this.chosenMandatoryToppings.length > 0) {
          generatedId += '_m' + this.chosenMandatoryToppings.map((x) => x.menucd).join('_m');
        }
      }
      // Append optional toppping menucds
      if (this.chosenOptionalToppings.length > 0) {
        generatedId += '_' + this.chosenOptionalToppings.map((x) => x.quantity + x.menucd).join('_');
      }

      // Final topping object. Includes mandatory topping and optional topping.
      let toppingObjects = [];
      if (this.chosenMandatoryToppings) {
        toppingObjects = this.chosenMandatoryToppings.concat(
          this.chosenOptionalToppings
        );
      } else {
        toppingObjects = this.chosenOptionalToppings;
      }

      this.setPOSTopping();
      let priceNonVAT = commonFunc.getPriceNonVAT(this.food.price, this.food.stuckb, this.food.tax_rate);
      let vatMainMenu = commonFunc.getOnlyVAT(this.food.price, this.food.stuckb, this.food.tax_rate);
      let price = parseInt(this.food.price, 10);
      // add vat for topping
      for (let top of toppingObjects) {
        let vat = commonFunc.getOnlyVAT(top.price, top.stuckb, top.tax_rate);
        top.vatSingle = vat * top.quantity;
        top.vat = vat * top.quantity * this.quantity;
        top.totalPrice = top.price * top.quantity * this.quantity;
        top.amountDiscountItem = 0;
        top.amountDiscountBill = 0;
        top.active_flag = true;
      }
      const cartObject = {
        menucd: this.food.menucd,
        menuid: generatedId,
        menunm: this.food.menunm,
        image: this.food.image,
        price: this.food.price,
        toppings: toppingObjects,
        quantity: this.quantity,
        stuckb: this.food.stuckb,
        vatSingle: Math.round(this.totalVAT / this.quantity), // VAT per unit (topping included)
        vat: this.totalVAT, // Total VAT (topping included)
        vatSingleWithoutTopping: vatMainMenu,
        vatWithoutTopping: vatMainMenu * this.quantity,
        priceWithTopping: this.food.priceWithTopping, // Price per unit
        priceNonVATWithTopping: this.food.priceNonVATWithTopping, // Price per unit (no VAT)
        amount: this.food.priceWithTopping * this.quantity, // Total price
        amountNonVAT: this.food.priceNonVATWithTopping * this.quantity, // Total price (no VAT)
        totalPrice: this.food.price * this.quantity,
        amountDiscountItem: 0, // amount of discount item
        amountDiscountBill: 0, // amount of discount bill
        tax_rate:this.food.tax_rate,
        meisaikb: Constants.POS_CONSTANT.MEISAIKB.MENU,
        description: this.food.description,
        smenucd: '',
        tankak: price,
        tankan: priceNonVAT,
        kingakuk: price * this.quantity,
        kingakun: priceNonVAT * this.quantity,
      };

      this.$store.commit('cartPush', cartObject);
      // this.saveDeliveryInfo();
    },

    saveDeliveryInfo() {

      let dataPos = {
        info: this.myCartInfo,
        id: this.currentDeliveryId,
      };

      let self = this;

      dataPos.info.start_time_check_out = '';
      dataPos.info.start_time_payment = '';
      dataPos.info.status = Constants.STATUS_ORDERING;

      OrderAPI.updateCart(dataPos)
        .then(() => {
          self.$store.commit(
            'cartUpdateDeliveryInfo',
            dataPos.info
          );
          self.clearMenu();
        })
        .catch((err) => {
          self.clearMenu();
          console.log(err);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    clearMenu() {
      this.isLoaded = false;
      this.toppingVAT = 0;
      this.amount = 0;
      this.quantity = 0;
      this.topping = null;
      this.chosenOptionalToppings = [];
      this.chosenMandatoryToppings = [];
      this.parentVAT = 0;
      this.totalVAT = 0;
      this.toppingVAT = 0;
      this.$router.push('/main-menu').catch(() => {});
      this.$store.commit('selectedMenuForDetailScreen', null);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/views/MenuToppingOption.scss";
</style>
