import OrderAPI from '@/api/order';
import Constants from '@/common/constant';

export default {
  methods: {
    /**
      * Save delivery info
      */
    async createCart() {
      try {
        // renew cart
        this.$store.commit('cartRemoveAllItem');
        this.$store.commit('cartUpdateDeliveryInfo', {
          ...this.myCartInfo,
          status: Constants.STATUS_ORDER_START,
        });

        let dataPos = {
          info: this.myCartInfo,
          language: this.language,
        };
        let { data: deliveryInfo } = await OrderAPI.createCart(dataPos);
        this.$store.commit('saveHistoryDeliveryId');
        this.$store.commit('saveDeliveryId', deliveryInfo.delivery_id);
        return true;
      } catch (error) {
        console.log({ error });
        return false;
      }
    },
  }
}
