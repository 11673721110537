<template lang="pug">
.row(:class="className")
  .col-lg-12.py-1.border.border-top-0.border-left-0.border-right-0.border-secondary(
      v-for="(item, x) of foods", :key="`${item.order_turn}-${item.order_line}-${item.quantity_index}`"
  )
    .checkout-product(v-if="item.isHalf")
      .flex-column
        .img-name-value
          .product-img
            a
              img(:src="item.left.image", alt="#")
          div(:title="item.left.name")
            span
              a.text-light-white.product-name
                | {{ item.left.name }}
            br
            div(v-for="(option, y) of item.left.options", :key="`${x}` + `${y}`")
              span.text-light-white.fw-700.product-topping
                | {{ option.name || '' }}
              br
        .img-name-value
          .product-img
            a
              img(:src="item.right.image", alt="#")
          div(:title="item.right.name")
            span
              a.text-light-white.product-name
                | {{ item.right.name }}
            br
            div(v-for="(option, y) of item.right.options", :key="`${x}` + `${y}`")
              span.text-light-white.fw-700.product-topping
                | {{ option.name || '' }}
              br
    .checkout-product(v-else)
      .img-name-value
        .product-img
          a
            img(:src="item.image", alt="#")
        .ml-2(:title="item.name")
          span
            a.text-light-white.product-name {{ item.name }}
          br
          div(v-for="(option, y) of item.options", :key="`${x}` + `${y}`")
            span.text-light-white.fw-700.product-topping
              | {{ option.name || '' }}
            br
    thumb-item-list(:type="'q1'", :data="{ ...item, index: x }", @onTapThumb="onTapThumb")

</template>

<script>
import ThumbItemList from './ThumbItemList';
export default {
  name: 'SurveyFoodItemList',
  components: { ThumbItemList },
  props: {
    foods: {
      type: Array,
      required: true,
    },
    className: {
      type: String,
      default: '',
    },
  },
  watch: {
    foods(n,o) {
      console.log(n,o);
    }
  },
  methods: {
    onTapThumb({ data }) {
      this.$emit('onTapThumb', {
        data,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
