const PREFIX_CATEGORY = 'category_';
import OrderAPI from '@/api/order';
import Constants from '@/common/constant';
import constant from '@/common/constant';
import commonFunc from '@/common/commonFunc';

export default {
  methods: {
    getCategory() {
      this.loading = true;
      OrderAPI.getMasterCategory({
        brandcd: this.myCartInfo.brandCode,
        storecd: this.myCartInfo.storeCode,
        lang: this.language,
      })
        .then((res) => {
          this.categoryList = res.data.data;
          let i = this.categoryList.length;
          while (i > 0) {
            this.categoryStates.push({ state: false });
            i--;
          }
          this.dataLoaded.category = true;

          this.getClass();
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    getClass() {
      OrderAPI.getMasterClass({
        brandcd: this.myCartInfo.brandCode,
        storecd: this.myCartInfo.storeCode,
        lang: this.language,
      })
        .then((res) => {
          this.classList = res.data.data;
          this.dataLoaded.class = true;

          // Select 1st Category and 1st Class on first load
          if (this.isFirstLoad) {
            this.current.categoryIndex = 0;
            this.current.categoryId = this.categoryList[0].bumoncd;
            this.current.classId = this.classList[0].bunruicd;
            this.categoryStates[0].state = true;
            this.classListDisplay = this.classList.filter(
              (x) => x.bumoncd === this.current.categoryId
            );
            this.dataLoaded.class = true;
            this.getMenu();
          }

          this.initTracker();
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    getMenu() {
      this.loading = true;
      OrderAPI.getMasterMenu({
        brandcd: this.myCartInfo.brandCode,
        storecd: this.myCartInfo.storeCode,
        bumoncd: this.current.categoryId,
        bunruicd: this.current.classId,
        language: this.language,
        theme: 'light',
      })
        .then((res) => {
          this.menuDisplay = {};
          const result = res.data.data;
          this.menuList = result;

          const lang = this.language.toUpperCase();

          // Filter acquired menu list by current Language
          this.menuListByLanguage = this.menuList.filter(
            (x) => x.langcd === lang
          );
          const menuLength = this.menuListByLanguage.length;

          // Calculate number of row to be displayed
          this.menuRow = Math.ceil(
            menuLength / constant.NUMBER_OF_MENU_IN_1_ROW
          );
          for (let i = 0; i < this.menuRow; i++) {
            this.menuDisplay['row' + i] = [];
          }

          const leftOver = this.menuListByLanguage.length % 3;

          if (leftOver !== 0) {
            for (let i = 0; i <= leftOver; i++) {
              this.menuListByLanguage.push({});
            }
          }

          // Menu in each row
          for (let i = 0; i < this.menuListByLanguage.length; i++) {

            let rowNumber = Math.floor(i / 3);
            // Add dummy image when Menu has no valid image
            if (!commonFunc.isValidImageLink(this.menuListByLanguage[i].img)) {
              this.menuListByLanguage[i].img = 'https://picsum.photos/id/102/300/300';
            }
            this.menuDisplay['row' + rowNumber].push(
              this.menuListByLanguage[i]
            );
          }

          this.dataLoaded.menu = true;
          this.isFirstLoad = false;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log('gettingMenuError', err);
        });
    },


    initTracker() {
      for (let i = 0; i < this.categoryList.length; i++) {
        this.tracker[PREFIX_CATEGORY + i] = [];
        let classOfCategory = this.classList.filter(
          (x) => x.bumoncd === this.categoryList[i].bumoncd
        );
        if (!classOfCategory) return;

        for (let j = 0; j < classOfCategory.length; j++) {
          if (j === 0) {
            this.tracker[PREFIX_CATEGORY + i].push({state: true, bunruicd: classOfCategory[j]['bunruicd']});
          } else {
            this.tracker[PREFIX_CATEGORY + i].push({state: false, bunruicd: classOfCategory[j]['bunruicd']});
          }
        }
      }
      if (this.isFirstLoad) {
        this.tracker[PREFIX_CATEGORY + '0'][0].state = true;
      }
    },

    /**
     * Set active category then display corresponding class.
     */
    setCategoryAndClass(categoryIndex, $event) {
      // Set active category
      this.categoryStates.map((x) => (x.state = false));
      this.categoryStates[categoryIndex].state = true;
      const categoryId = $event.target.id;
      this.current.categoryIndex = categoryIndex;
      this.current.categoryId = categoryId;

      // Display corresponding class
      this.classListDisplay = this.classList.filter(
        (x) => x.bumoncd === categoryId
      );
      const newTracker = this.tracker[PREFIX_CATEGORY + categoryIndex].find(x => x.state === true);
      this.current.classId = newTracker['bunruicd'];
      this.getMenu();
    },

    /**
         * Set active class then load corresponding menu.
         */
    setClassAndMenu(classIndex, $event) {
      // Set active class
      const classId = $event.target.id;
      this.current.classId = classId;
      for (let i = 0; i < this.tracker[PREFIX_CATEGORY + this.current.categoryIndex].length; i++) {
        this.tracker[PREFIX_CATEGORY + this.current.categoryIndex][i].state = false;
      }
      this.tracker[PREFIX_CATEGORY + this.current.categoryIndex][classIndex].state = true;

      // Load corresponding menu
      this.getMenu();
    },

    /**
     * Save delivery info
     */
    saveDeliveryInfo() {

      let dataPos = {
        info: this.myCartInfo,
        id: this.currentDeliveryId,
      };

      let self = this;

      dataPos.info.start_time_check_out = '';
      dataPos.info.start_time_payment = '';
      dataPos.info.status = Constants.STATUS_ORDERING;

      OrderAPI.updateCart(dataPos)
        .then(() => {
          self.$store.commit(
            'cartUpdateDeliveryInfo',
            dataPos.info
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    openCart() {
      if (this.isCartScreen) {
        this.$router.push('/my-cart/').catch(() => {});
      } else {
        this.$router.push('/main-menu/').catch(() => {});
      }
    },

    toHalfHalf() {
      this.$router.push('/menu-pizza').catch(() => {});
    },

    replaceDefaultImage(e) {
      e.target.src = 'https://picsum.photos/id/102/300/300';
    },
  }
}
