<template lang="pug">
section.page-full-height
  .row.mx-0
    div(class="col-12 main__menu pt-4")
      h5.mt-5.text-center(v-if="cartCount === 0") {{ $t("cart.empty") }}
      .row.cart-item(v-for="item of myCartInfo.foods", :key="item.menuid")
        .col-lg-3.col-md-3.col-5.pl-0.pr-3
          b-card-img.rounded-0.item-img(:src="item.image", alt="Image")
        .col-lg-9.col-md-9.col-7.px-0
          .row.w-100.mx-0
            .col-10.px-0.pt-2
              h4.item-name.font-brandon {{ item.menunm }}
              h6.unit-price(v-if="item.toppings.length > 0") {{ item.priceWithTopping | formatCurrency }}
              h6.unit-price(v-else) {{ item.price | formatCurrency }}
            .col-2.pr-2
              i.fas.fa-times.btn-delete-item(@click="removeMenu(item)")
          .area-detail(v-if="menuChosenToppings(item.menuid)") {{ menuChosenToppings(item.menuid) }}
          .area-price
            .quantity-control.px-0
              b-btn.btn-quantity(:disabled="item.quantity <= 1", @click="decrease(item)") -
              span.quantity.px-3 {{ item.quantity }}
              b-btn.btn-quantity(@click="increase(item)") +
            span.padding-equal.px-4 =
            span.price-total
              | {{ item.priceWithTopping | formatCurrency }}
      .bottom-info.d-block.d-lg-none(v-if="cartCount > 0")
        .row.w-100.mx-0.mb-2
          .col-6.px-0
            h6.text-left {{ $t("cart.subTotal") + ": " }}
          .col-6.px-0
            h6.text-right {{ myCartInfo.afterPromotion.subTotal | formatCurrency }}
        .row.w-100.mx-0.mb-2(
          v-for="(v,k,i) in myCartInfo.afterPromotion.taxRates"
        )
          .col-6.px-0
            h6.text-left {{ $t("cart.vat") }} {{k}}%
          .col-6.px-0
            h6.text-right {{ v | formatCurrency }}
        .row.w-100.mx-0.mb-2
          .col-6.px-0
            h6.text-left {{ $t("cart.total") + ": " }}
          .col-6.px-0
            h6.text-right {{ myCartInfo.afterPromotion.total | formatCurrency }}
      .row.w-100.mx-0.pt-3.justify-center
        .px-0(:class=`
          cartCount > 0
            ? 'col-lg-12 col-6 back'
            : 'back-empty col-12 text-center'
        `)
          b-link(to="main-menu")
            b-button(variant="success")
              b-icon(icon="arrow-left-square", aria-hidden="true", class="mr-2")

              | {{ $t("cart.backToMenu") }}
        .confirm.col-6.px-0.d-block.d-lg-none(v-if="cartCount > 0")
          b-link(to="confirm-order")
            b-btn.fa-pull-right.mr-2(variant="primary")
              | {{ $t("button.proceedToCheckout") }}
    cart-info(:data="cartInfo", @callback="returnFunction")

</template>

<script>
import OrderAPI from '@/api/order';
import commonFunc from '@/common/commonFunc';
import { mapGetters } from 'vuex';
import CartInfo from '@/components/common/CartInfo.vue';
import Constants from '@/common/constant';

export default {
  name: 'MyCart',
  components: {
    CartInfo,
  },
  data() {
    return {
      cartInfo: {
        subTotal: null,
        vat: null,
        Total: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      'cartCount',
      'myCartInfo',
      'menuChosenToppings',
      'currentDeliveryId',
      'language',
    ]),
  },

  mounted() {
    if (!this.currentDeliveryId) {
      this.$router.push('/');
    }
  },

  methods: {
    /**
     * Get System configuration
     */
    returnFunction(data) {
      this.returnData = data;
    },

    priceNonVAT(price, stuckb) {
      return commonFunc.getPriceNonVAT(price, stuckb);
    },

    increase(item) {
      this.$store.commit('cartIncreaseQuantity', item.menuid);
      this.updateDeliveryAll();
    },

    decrease(item) {
      this.$store.commit('cartDecreaseQuantity', item.menuid);
      this.updateDeliveryAll();
    },

    removeMenu(item) {
      this.$bvModal
        .msgBoxConfirm(this.$t('message.deleteCartItem'), {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('button.delete'),
          cancelTitle: this.$t('button.cancel'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((comfirmed) => {
          if (comfirmed) {
            this.$store.commit('cartRemoveMenu', item.menuid);
            this.updateDeliveryAll();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetCart() {
      this.$store.commit('cartRemoveAllItem');
      this.updateDeliveryAll();
    },
    updateDeliveryAll() {
      let dataPos = {
        info: this.myCartInfo,
        id: this.currentDeliveryId,
      };

      let self = this;

      dataPos.info.status =
        this.cartCount == 0
          ? Constants.STATUS_ORDER_START
          : Constants.STATUS_ORDERING;

      OrderAPI.updateCart(dataPos)
        .then(() => {
          self.$store.commit('cartUpdateDeliveryInfo', dataPos.info);
        })
        .catch((err) => {
          self.$gHandleError(
            self.$t('handleError.loadMenuPizzaError'),
            self.$t('handleError.titleError')
          );
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/views/MyCart.scss";
</style>
