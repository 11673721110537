<template lang="pug">
.card.menu-item(:class="clickable ? 'clickable' : ''", @click="addToCart(menu)")
  .card-body(:class="clickable ? '' : 'px-0'")
    .w-100.mx-0.row
      .col-md-12.col-6.px-0
        .d-none.d-md-block
          .add-cart(v-if="menu.menucd")
            i.fas.fa-shopping-bag
        img.icon-right.d-none.d-md-block(v-if="menu.menu_status <= 5 && menu.menu_status > 0 && menu.menucd", :src="getIconMenuStatus(menu.dataicon, menu.menu_status)")
        b-card-img(v-if="menu.menucd", :src="menu.img", img-fluid)
        .misc.d-none.d-sm-block(v-if="menu.menucd", v-b-tooltip.hover, title)
          div
            img.icon-allergy(v-for="(item, indexAll) in menu.allergy_arr", v-if="getIconMenuAllergy(menu.dataicon ,item)", :key="indexAll", :src="getIconMenuAllergy(menu.dataicon ,item)")
      .col-md-12.col-6.px-0
        .px-4
          h4.item-name.font-brandon {{ menu.menuname }}
        div
          b-card-text.d-block.d-md-none.font-brandon(v-if="menu.menucd") {{ calculateMenuPrice(menu) | formatCurrency }}
        div(class="cart-button-wrapper pt-4")
          b-button.cart-button.d-block.d-md-none(
            variant="primary",
            @click="addToCart(menu, true)",
            :class="isClickedAddToCart"
          )
            span.add-to-cart {{ $t("button.addToCart") }}
            span.added Item added
            i.fa.fa-shopping-cart
            i.fa.fa-square

  b-card-text.d-none.d-md-block(v-if="menu.menucd") {{ calculateMenuPrice(menu) | formatCurrency }}

</template>

<script>
import commonFunc from '@/common/commonFunc';
import Constants from '@/common/constant';

export default {
  name: 'MenuItem',
  props: {
    menu: {
      type: Object,
      required: true,
    },
    clickable: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      isClickedAddToCart: '',
    }
  },

  methods: {
    getIconMenuAllergy(assetsUrl, allergyId) {
      if (allergyId == '01') return assetsUrl + 'icon_allergy_nut.png';
      else if (allergyId == '02')
        return assetsUrl + 'icon_allergy_pork.png';
      else if (allergyId == '03')
        return assetsUrl + 'icon_allergy_beef.png';
      else if (allergyId == '04')
        return assetsUrl + 'icon_allergy_seaweed.png';
      else if (allergyId == '05')
        return assetsUrl + 'icon_allergy_egg.png';
      else if (allergyId == '06')
        return assetsUrl + 'icon_allergy_anchovy.png';
      else if (allergyId == '07')
        return assetsUrl + 'icon_allergy_fish.png';
      else if (allergyId == '08')
        return assetsUrl + 'icon_allergy_spicy.png';
      else if (allergyId == '09')
        return assetsUrl + 'icon_allergy_craf.png';
      else if (allergyId == '10')
        return assetsUrl + 'icon_allergy_shirmp.png';
      else return null;
    },

    /**
         * Calculate menu price to be displayed based on VAT flag [stuckb]
         * Always display price with VAT excluded.
         * + stuckb = 0: No VAT
         * + stuckb = 1: Excluded VAT
         * + stuckb = 2: Included VAT
         */
    calculateMenuPrice(menu) {
      return menu.baika;
    },

    getIconMenuStatus(assetsUrl, menuStatus) {
      if (menuStatus == '1') return assetsUrl + 'icon-new.png';
      else if (menuStatus == '2') return assetsUrl + 'icon-recommend.png';
      else if (menuStatus == '3') return assetsUrl + 'icon-season.png';
      else if (menuStatus == '4')
        return assetsUrl + 'icon-vegetarian.png';
      else if (menuStatus == '5')
        return assetsUrl + 'icon-best-seller.png';
    },


    /**
         * Add selected menu to cart
         * Cart is managed by global state
         */
    addToCart(menu, allow = false) {
      if(this.clickable || allow) {

        this.$store.commit('selectedMenuForDetailScreen', {
          menucd: menu.menucd,
          menunm: menu.menuname,
          image: menu.img,
          stuckb: menu.stuckb,
          quantity: 1,
          description: menu.description,
          price: parseInt(menu.baika, 10),
          soldout: menu.soldout,
          tax_rate: menu.tax_rate,
        });

        if (menu.has_topping) {
          if (menu.soldout) return;
          this.$router.push('/detail', () => {
            this.$root.$emit('reloadMenu');
          });
          // this.$router.push({name: 'MenuToppingOption',  params: { menuname: menu.menuname }})
        } else {

          let priceNonVAT = commonFunc.getPriceNonVAT(menu.baika, menu.stuckb, menu.tax_rate);
          let vat = priceNonVAT * menu.tax_rate / 100;
          let price = parseInt(menu.baika, 10);

          const cartObject = {
            menucd: menu.menucd,
            menuid: menu.menucd,
            menunm: menu.menuname,
            image: menu.img,
            price: price,
            toppings: [],
            quantity: 1,
            stuckb: menu.stuckb,
            vatSingle: vat,
            vat: vat,
            vatSingleWithoutTopping: vat,
            vatWithoutTopping: vat,
            priceWithTopping: price,
            priceNonVATWithTopping: priceNonVAT,
            amount: price,
            amountNonVAT: priceNonVAT,
            totalPrice: price,
            amountDiscountItem: 0, // amount of discount item
            amountDiscountBill: 0, // amount of discount bill
            tax_rate: menu.tax_rate,
            meisaikb: Constants.POS_CONSTANT.MEISAIKB.MENU,
            description: menu.description,
            smenucd: '',
            tankak: price,
            tankan: priceNonVAT,
            kingakuk: price,
            kingakun: priceNonVAT,
          };

          this.$store.commit('cartPush', cartObject);
          // update status delivery
          // this.$root.$emit('saveDeliveryInfo');

          this.isClickedAddToCart = 'clicked';
          setTimeout(()=> {this.isClickedAddToCart = ''}, 3000);
          this.showToast();
        }
      }
    },

    showToast() {
      const h = this.$createElement;
      const vNodesMsg = h(
        'p',
        { class: ['mb-0'] },
        [
          h('b-spinner', { props: { type: 'grow', small: true } }),
          ` Added to cart `,
          h('b-spinner', { props: { type: 'grow', small: true } })
        ]
      );
      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        solid: true,
        variant: 'info'
      })
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/views/MenuItem.scss";
</style>
