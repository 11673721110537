import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store/store'
import VueI18n from 'vue-i18n';
import ErrorHandler from './plugins/error-handler'
// @ts-ignore
import vnMessage from '@/lang/lang_vn.json'
// @ts-ignore
import enMessage from '@/lang/lang_en.json'
Vue.config.productionTip = false

import TableInfoMixin from '@/mixins/tableInfo';
import moment from 'moment';

// Import Vue Bootstrap
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import '@/assets/css/style.css'
import '@/assets/css/responsive.css'
import '@/assets/css/font-awesome.css'
import '@/assets/styles/main.scss'


Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Vuex)
Vue.use(VueI18n)
Vue.use(ErrorHandler)

const messages = {
  vn: vnMessage,
  en: enMessage
}
let i18n = new VueI18n({
  locale: store.getters.language, // set locale
  messages,
  fallbackLocale: store.getters.language
})

Vue.filter('formatCurrency', function (value: String) {
  if (value === null || value === '') {
    return;
  }
  return String(value).replace(/(.)(?=(\d{3})+$)/g, '$1,');
});

Vue.filter('formatTime', function (value: any) {
  if (value == undefined || value == '') return '';
  return moment(String(value)).format('HH:mm');
});

const MyPlugin = {
  install() {
    Vue.prototype.$gHandleError = function (err: String, title: String) {
      this.$bvModal.msgBoxOk(err, {
        title: title,
        buttonSize: 'lg',
        okVariant: 'danger',
        centered: true,
        size: 'sm',
        footerClass: 'p-2',
      });
    };
    Vue.prototype.$gMsgBoxOk = function (message: String, title: String) {
      return this.$bvModal.msgBoxOk(message, {
        title: title,
        buttonSize: 'lg',
        okVariant: 'danger',
        centered: true,
        size: 'sm',
        footerClass: 'p-2',
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
        hideHeaderClose: true,
      });
    };

    Vue.prototype.$gMsgBoxConfirm = function (message: String, title: String, okTitle = 'OK', cancelTitle = 'NO') {
      return this.$bvModal.msgBoxConfirm(message, {
        title: title,
        size: 'sm',
        buttonSize: 'lg',
        okVariant: 'danger',
        okTitle: okTitle,
        cancelTitle: cancelTitle,
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: true,
        noCloseOnBackdrop: true,
      });
    };
  },
};
Vue.use(MyPlugin);
Vue.mixin(TableInfoMixin);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
