<template lang="pug">
section.page-full-height
  .w-100.mx-0.row
    div(class="col-12 main__menu")
      .pt-2
        .row.w-100.mx-0
          .col-12.px-0
            h3.text-light-black.fw-600.title {{ $t("booking.YourOrder") }}
          .col-12.px-0(v-for="item of myCartInfo.foods", :key="item.menuid")
            .checkout-product
              .img-name-value
                .product-img
                  a
                    img(:src="item.image", alt="#")
                .product-value
                  span.text-light-white {{ item.quantity }}
                div(:title="item.menunm")
                  span
                    a.text-light-white.product-name
                      | {{ item.menunm }}
                  br
                  span.text-light-white.fw-700.product-topping
                    | {{ menuChosenToppings(item.menuid) }}
              .product-price
                span.text-light-white
                  | {{ item.priceWithTopping | formatCurrency }}
      .row.w-100.mx-0
        .col-12.px-0
          .price-table.u-line
            .item
              span.text-light-white {{ $t("booking.subTotal") }}
                | :
              span.text-light-white
                | {{ myCartInfo.afterPromotion.subTotal | formatCurrency }}
            .item(
              v-for="(v,k,i) in myCartInfo.afterPromotion.taxRates"
            )
              span.text-light-white {{ $t("booking.vat") }} {{k}}%
                | :
              span.text-light-white
                | {{ v | formatCurrency }}
            .item
              span.text-light-white {{ $t("booking.total") }}
                | :
              span.text-light-white
                | {{ myCartInfo.afterPromotion.total | formatCurrency }}
      .row.w-100.mx-0
        button.button.btn-primary.mx-0(@click="saveDeliveryInfo(true)") {{ $t("booking.confirm") }}
  Loader(v-show="loading")

</template>
<script>
import Constants from '@/common/constant';
import OrderAPI from '@/api/order';
import Loader from '@/components/Loader';
import { QrcodeStream } from 'vue-qrcode-reader';
import { mapGetters } from 'vuex';
import cartMixin from '@/mixins/cart';

export default {
  name: 'ConfirmOrder',
  components: {
    QrcodeStream,
    Loader,
  },
  mixins: [cartMixin],
  data: function () {
    return {
      clickSave: false,
      cartInfo: {},
      cart: null,
      foods: null,
      subTotal: null,
      vat: null,
      total: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      'cartCount',
      'myCartInfo',
      'menuChosenToppings',
      'getPOSObject',
      'currentDeliveryId',
      'language',
    ]),
  },
  async mounted() {
    this.loading = true;
    await this.checkTableInfo();
    this.loading = false;
  },
  methods: {
    /**
     * Save delivery info
     */
    saveDeliveryInfo(isConfirmOrder) {
      this.clickSave = true;
      this.loading = true;

      let dataPos = {
        info: { ...this.myCartInfo },
        id: this.currentDeliveryId,
        isConfirm: isConfirmOrder ? true : false,
        userid: Constants.SYSTEM_USER,
        used: true,
      };

      dataPos.info.status = Constants.STATUS_ORDER_SENT;
      dataPos.info.uridakak = this.getPOSObject.uridakak;
      dataPos.info.uridakan = this.getPOSObject.uridakan;
      dataPos.info.uchizei = this.getPOSObject.uchizei;
      dataPos.info.sotozei = this.getPOSObject.sotozei;
      dataPos.info.gokeikin = this.getPOSObject.gokeikin;
      dataPos.info.uchigokei = 0;
      dataPos.info.nebiuzkin = 0;
      dataPos.info.menucnt = this.getPOSObject.menucnt;
      OrderAPI.updateCart(dataPos).then(() => {
        this.createCart();
        this.$router.push('/delivery-status').then(()=>{});
      }).catch((error) => {
        let self = this;
        this.$gHandleError(
          self.$t('handleError.loadDeliveryInforError'),
          self.$t('handleError.titleError')
        );
        console.log(error);
      }).finally(()=> {
        this.loading = false;
      });

    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/views/ConfirmOrder.scss";
</style>
