
export default {
    install(Vue, options) {
        window.onerror = function (msg, url, line, col, error) {
            console.log({ msg, url, line, col, error });
        };
    
        /**
         * This is a global promise error handler, with these lines, we don't need to add catch to async/await
         * or .catch in Promise syntax
         */
        window.addEventListener("unhandledrejection", function ({ reason }) {
            // Vue.store.commit("error/set", reason);
        });
    }
}
