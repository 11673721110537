import session from './session'
import { RootAPI } from './index'

export default {
  /**
   * Get menu
   */
  getMenu () {
    return session.post(RootAPI + 'delivery/test-api')
  },

  getMasterCategory(req) {
    return session.post(RootAPI + 'delivery/get-master-category', req)
  },

  getMasterClass(req) {
    return session.post(RootAPI + 'delivery/get-master-class', req)
  },

  getMasterMenu(req) {
    return session.post(RootAPI + 'delivery/get-master-menu', req)
  },

  /**
   * Get menu pizza
   */
  getItemMenuPizza (req) {
    return session.post(RootAPI + 'delivery/get-item-menu-pizza', req)
  },

  /**
   * Get detail food with topping
   */
  getDetailFoodWithTopping (params) {
    return session.post(RootAPI + 'delivery/get-menu-detail-with-topping', params)
  },

  searchPromotion(params) {
    return session.post(RootAPI + 'delivery/check-is-available-promotion', params)
  },

  updatePromotionUsage(params) {
    return session.post(RootAPI + 'delivery/update-promotion-usage', params)
  },

  /**
   * Insert delivery info
   */
  createCart (params) {
    return session.post(RootAPI + 'v2/byod/create-cart', params)
  },

  /**
   * Update delivery info
   */
  updateCart (params) {
    return session.post(RootAPI + 'v2/byod/update-cart', params);
  },

  /**

  */
  getStatusOrder (params) {
    return session.post(RootAPI + 'v2/byod/status-foods', params);
  },

  /**
   * Get delivery info by id
   */
  getAllOrder (params) {
    return session.post(RootAPI + 'v2/byod/order', params);
  },

  /**
   * Get delivery info by id
   */
  getSurveyFoods (params) {
    return session.post(RootAPI + 'v2/byod/survey-foods', params);
  },

}
