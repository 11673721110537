import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Constant from '@/common/constant';
import commonFunc from '@/common/commonFunc';
import moment from 'moment';
moment.suppressDeprecationWarnings = true;
Vue.use(Vuex);
import OrderAPI from '@/api/order';

const initCart = {
  foods: [],
  cash: 0,
  count: 0,
  subTotal: 0,
  total: 0,
  vat: 0,
  status: Constant.STATUS_ORDER_START,
  isbyod: 1,
  deliveryFeeItem: {},
  deliveryFeeItemV2: null,
  listPromotion: [],
  amountDiscountFeeship: 0,
  coupon: 0,
  afterPromotion: {
    subTotal: 0,
    vat: 0,
    total: 0,
    taxRates: {},
    vats:[],
  },
};

const initTable = {
  tableNo: '',
  tableName: '',
  storeId: 0,
  brandCode: '',
  brandId: '',
  posno: '001',
};

const initState = {
  cart: { ...initCart , ...initTable },
  deliveryId: null,
  oldDeliverys: [],
  language: 'en',
  selectedMenu: {},
};

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: { ...initState },

  getters: {
    // Get delivery data object
    myCartInfo: (state) => {
      return state.cart;
    },

    language: (state) => {
      return state.language;
    },

    currentDeliveryId: (state) => {
      return state.deliveryId;
    },

    getOldDeliverys: (state) => {
      return state.oldDeliverys;
    },

    tableInfo: (state) => {
      return {
        kjymd: state.cart.kjymd,
        odseq: state.cart.odseq,
      };
    },

    // Count all item in carts (by quantity)
    cartCount: (state) => {
      if (state.cart.status === Constant.STATUS_ORDERING) {
        return state.cart.count;
      } else {
        return 0;
      }
    },

    // Get chosen toppings (string)
    menuChosenToppings: state => menuid => {
      const menu = state.cart.foods.find(x => x.menuid === menuid);
      if (!menu) return;
      if (menu.toppings.length === 0) return;

      let toppings = '';
      for (let i = 0; i < menu.toppings.length; i++) {
        toppings = toppings + menu.toppings[i].menunm;
        toppings += i === menu.toppings.length - 1 ? '' : ', ';
      }
      return toppings;
    },

    findByMenuId: (state) => (menuid) => {
      return state.cart.foods.find(x => x.menuid === menuid);
    },

    findQuantityByMenuCd: (state) => (menuid) => {
      const menu = state.cart.foods.find(x => x.menuid === menuid);
      if (!menu) return 0;

      return menu.quantity;
    },

    getPOSObject: (state, getter) => {
      let toppingSotozei = 0;
      let toppingUchizei = 0;
      let toppingCount = 0;
      const uridakak = state.cart.subTotal;
      const uridakan = state.cart.subTotal;
      const gokeikin = state.cart.total;

      const sotozei = state.cart.foods.reduce(function(prev, cur) {
        if (cur.toppings.length > 0) {
          for (let i = 0; i < cur.toppings.length; i++) {
            toppingCount += cur.toppings[i].quantity;
            toppingSotozei += commonFunc.getSotozei(
              cur.toppings[i].price,
              cur.toppings[i].quantity,
              cur.toppings[i].stuckb
            );
          }
        }
        return (
          prev + commonFunc.getSotozei(cur.price, cur.quantity, cur.stuckb)
        );
      }, 0);

      const uchizei = state.cart.foods.reduce(function(prev, cur) {
        if (cur.toppings.length > 0) {
          for (let i = 0; i < cur.toppings.length; i++) {
            toppingCount += cur.toppings[i].quantity;
            toppingUchizei += commonFunc.getUchizei(
              cur.toppings[i].price,
              cur.toppings[i].quantity,
              cur.toppings[i].stuckb
            );
          }
        }
        return (
          prev + commonFunc.getUchizei(cur.price, cur.quantity, cur.stuckb)
        );
      }, 0);

      return {
        uridakak: uridakak,
        uridakan: uridakan,
        sotozei: sotozei + toppingSotozei,
        uchizei: uchizei + toppingUchizei,
        gokeikin: gokeikin,
        menucnt: state.cart.count + toppingCount
      };
    },

    selectedMenu: (state) => state.selectedMenu,

    cartAfterPromotion:  (state) => state.cart.afterPromotion,
  },

  mutations: {
    setLanguage(state, language) {
      state.language = language;
    },
    /**
     * Either PUSH new menu to cart or INCREASE QUANTITY of existing menu. Uses Menuid.
     * [Menu without topping] :    Menuid = Menucd
     * [Menu with topping]    :    Menuid = Menucd + Concatenation of all Menucd of Toppings
     *    Example: Menuid = 10010000_99000001_99000002
     *        + 10010000            : Menucd of item that has topping
     *        + 99000001, 99000002  : Menucd of topping
     */
    cartPush(state, cartObject) {
      if (!cartObject || !cartObject.menuid) return;

      if (state.cart.count === 0) {
        state.cart.foods.push(cartObject);
      } else {
        let existingMenu = this.getters.findByMenuId(cartObject.menuid);
        if (!existingMenu) {
          state.cart.foods.push(cartObject);
        } else {
          existingMenu.quantity += cartObject.quantity;
          existingMenu.vat += cartObject.vat;
          existingMenu.vatWithoutTopping += cartObject.vatSingleWithoutTopping;
          existingMenu.amount += cartObject.amount;
          existingMenu.amountNonVAT += cartObject.amountNonVAT;
          existingMenu.totalPrice += cartObject.totalPrice;
          existingMenu.kingakuk += cartObject.kingakuk;
          existingMenu.kingakun += cartObject.kingakun;

          if (existingMenu.toppings.length > 0) {
            // Amount của menu topping đã nhân Quantity rồi nên cộng thẳng
            existingMenu.toppings.map(top => {
              // Mandatory topping: +1
              if (top.meisaikb === '2') {
                top.POSquantity++;
              } else {
                // Optional topping: + parent quantity
                top.POSquantity += top.quantity;
              }
              top.kingakuk = top.tankak * top.POSquantity;
              top.kingakun = top.tankan * top.POSquantity;

              // update vat
              top.vat = top.vatSingle * existingMenu.quantity;
              top.totalPrice = top.price * top.quantity * existingMenu.quantity;
            });
          }
        }
      }

      this.commit('calculateCart', { isSubmitted: false });

    },

    /**
     * Increase quantity of an existing menu in cart by 1.
     */
    cartIncreaseQuantity(state, menuid) {
      let existingMenu = this.getters.findByMenuId(menuid);

      if (!existingMenu) return;

      existingMenu.quantity += existingMenu.quantity;
      existingMenu.vat += existingMenu.vat;
      existingMenu.vatWithoutTopping += existingMenu.vatSingleWithoutTopping;
      existingMenu.amount += existingMenu.amount;
      existingMenu.amountNonVAT += existingMenu.amountNonVAT;
      existingMenu.totalPrice += existingMenu.totalPrice;
      existingMenu.kingakuk += existingMenu.kingakuk;
      existingMenu.kingakun += existingMenu.kingakun;

      if (existingMenu.toppings.length > 0) {
        // Amount của menu topping đã nhân Quantity rồi nên cộng thẳng
        existingMenu.toppings.map(top => {
          // Mandatory topping: +1
          if (top.meisaikb === '2') {
            top.POSquantity++;
          } else {
            // Optional topping: + parent quantity
            top.POSquantity += top.quantity;
          }
          top.kingakuk = top.tankak * top.POSquantity;
          top.kingakun = top.tankan * top.POSquantity;

          // update vat
          top.vat = top.vatSingle * existingMenu.quantity;
          top.totalPrice = top.price * top.quantity * existingMenu.quantity;
        });
      }

      this.commit('calculateCart', { isSubmitted: false });
    },

    /**
     * Decrease quantity of an existing menu in cart by 1.
     * When decreasing quantity to 0, corresponding menu will be removed.
     */
    cartDecreaseQuantity(state, menuid) {
      let menu = this.getters.findByMenuId(menuid);
      if (!menu) return;

      // In case 1 -> 0, completely remove the menu object from cart
      if (menu.quantity === 1) {
        const index = state.cart.foods.indexOf(menu);
        state.cart.foods.splice(index, 1);
      } else {
        menu.quantity -= 1;
        menu.vat -= menu.vatSingle;
        menu.vatWithoutTopping -= menu.vatSingleWithoutTopping;
        menu.amount -= menu.priceWithTopping;
        menu.amountNonVAT -= menu.priceNonVATWithTopping;
        menu.totalPrice -= menu.price;
        menu.kingakuk -= menu.tankak;
        menu.kingakun -= menu.tankan;

        if (menu.toppings.length > 0) {
          menu.toppings.map(top => {
            // Mandatory topping: +1
            if (top.meisaikb === '2') {
              top.POSquantity--;
            } else {
              // Optional topping: + top quantity
              top.POSquantity -= top.quantity;
            }
            top.kingakuk = top.tankak * top.POSquantity;
            top.kingakun = top.tankan * top.POSquantity;

            // update vat
            top.vat = top.vatSingle * menu.quantity;
            top.totalPrice = top.price * top.quantity * menu.quantity;
          });
        }
      }

      this.commit('calculateCart', { isSubmitted: false });
    },

    /**
     * Remove a menu from cart and update count.
     */
    cartRemoveMenu(state, menucd) {
      const menu = this.getters.findByMenuId(menucd);
      if (!menu) return;
      const index = state.cart.foods.indexOf(menu);
      state.cart.foods.splice(index, 1);

      this.commit('calculateCart', { isSubmitted: false });
    },

    cartUpdateDeliveryInfo(state, info) {
      state.cart.subTotal = info.subTotal;
      state.cart.vat = info.vat;
      state.cart.total = info.total;
      state.cart.orderNo = info.orderNo;
      state.cart.status = info.status;
      state.cart.cash = info.cash;
      state.cart.tableNo = info.tableNo;
      state.cart.tableName = info.tableName;
      state.cart.brandId = info.brandId;
      state.cart.brandCode = info.brandCode;
      state.cart.storeCode = info.storeCode;

      state.cart.storeId = info.storeId;
      if (info.kjymd != undefined) {
        state.cart.kjymd = info.kjymd;
        state.cart.odseq = info.odseq;
      }
    },

    cartUpdate(state, cart) {
      state.cart = cart;
      if (cart.status == Constant.STATUS_ORDERING) {
        state.cart.count = cart.foods.length;
      } else {
        state.cart.count = 0;
      }
    },

    cartRemoveAllItem(state) {
      Object.assign(state.cart, initCart);
    },

    selectedMenuForDetailScreen(state, menu) {
      if (menu) {
        state.selectedMenu = {...menu};
      } else {
        state.selectedMenu = menu;
      }
    },

    saveDeliveryId(state, deliveryId) {
      state.deliveryId = deliveryId;
    },

    saveHistoryDeliveryId(state) {
      if (state.deliveryId) {
        state.oldDeliverys.push(state.deliveryId);
      }
    },

    // item -> bill -> voucher -> free point
    // limit = 0: no limit. limit > 0: has limit
    calculatePromotion(state) {

      let subTotal = state.cart.subTotal;
      let remainingSubTotal = subTotal;

      const discountItems = state.cart.listPromotion.filter(
        x => x.pmt_on === Constant.PROMOTION.ON.ITEM
      );
      const discountBills = state.cart.listPromotion.filter(
        x => [
          Constant.PROMOTION.DISCOUNT.PERCENT,
          Constant.PROMOTION.DISCOUNT.MONEY
        ].includes(x.pmt_type) && x.pmt_on === Constant.PROMOTION.ON.BILL
      );
      const vouchers = state.cart.listPromotion.filter(
        x => x.pmt_type === Constant.PROMOTION.DISCOUNT.CASH_VOUCHER && x.pmt_on === Constant.PROMOTION.ON.BILL
      );

      const discountFeeships = state.cart.listPromotion.filter(x => x.pmt_on === Constant.PROMOTION.ON.FEESHIP);

      let itemAmount = 0, billAmount = 0, voucherAmount = 0;
      // ITEM DISCOUNT
      if (discountItems?.length > 0) {

        discountItems.forEach((x, i) => {
          // check if cart contains conditional items
          let amount = 0;
          let promoApplyItem; // promotion's specified discountable items
          let cartApplyItem; // discountable items of cart
          let appliableMenu; // menu to be discounted
          const hasCondition = x.item_by_item || false;

          if (hasCondition) {
            let condition = x.condition_items.selectedItems.map(x => x.menucd);
            if (!state.cart.foods.some(x => x.active_flag && condition.includes(x.menucd))) {
              return;
            }

            promoApplyItem = x.apply_items.selectedItems.map(x => x.menucd);
            cartApplyItem = state.cart.foods.filter(x => x.active_flag && promoApplyItem.includes(x.menucd));
            if (cartApplyItem?.length === 0) return;

            const similar = cartApplyItem.some(x => x.active_flag && condition.includes(x.menucd));

            // Not similar: Cart has A, B. Require A, Discount B => don't care quantity
            if (!similar) {
              appliableMenu = cartApplyItem.reduce((prev, cur) => prev =
                          ( prev.amount < cur.amount &&  !prev.promotionId) ? prev : cur);
            }
            // Similar: Cart has A. Require A, Discount A => Quantity of A must be >= 2
            if (similar) {
              // filter appliable cart items having quantity >= 2
              let atLeastTwo =  cartApplyItem.filter(x => (x.active_flag && x.quantity >= 2 && !x.promotionId));
              if (atLeastTwo.length === 0) return;

              appliableMenu = atLeastTwo.reduce((prev, cur) => prev =
                          (prev.amount < cur.amount && !prev.promotionId) ? prev : cur);

              if (!appliableMenu) return;
            }
          }

          if (!hasCondition) {
            promoApplyItem = x.apply_items.selectedItems.map(x => x.menucd);
            cartApplyItem = state.cart.foods.filter(x => x.active_flag && promoApplyItem.includes(x.menucd));
            if (cartApplyItem?.length === 0) return;
            // extract 1 menu for discount. prioritize lowest-price menu
            appliableMenu = cartApplyItem.reduce((prev, cur) => prev =
                           ( prev.amount < cur.amount && !prev.promotionId === false ) ? prev : cur);
          }

          if (!appliableMenu) return;

          // discount is applied to original non-VAT price. ignores topping.
          let menuPrice = commonFunc.getPriceNonVAT(parseInt(appliableMenu.price), appliableMenu.stuckb);

          if (x.pmt_type === Constant.PROMOTION.DISCOUNT.PERCENT) {
            amount = Math.round(menuPrice * x.pmt_type_detail / 100);
            if (amount >= x.limit && x.limit > 0) amount = x.limit;
          }

          if (x.pmt_type === Constant.PROMOTION.DISCOUNT.MONEY) {
            amount = (menuPrice*appliableMenu.quantity >= x.pmt_type_detail)
              ? x.pmt_type_detail : menuPrice*appliableMenu.quantity;
          }

          appliableMenu.promotionId = x.id;
          x.menucd = appliableMenu.menucd;

          // save amount
          x.promotionAmount = amount;
          appliableMenu.amountDiscountItem = amount;
          remainingSubTotal -= amount;
        });

        itemAmount = discountItems.reduce((prev, cur) => {
          return prev + cur.promotionAmount;
        }, 0);
      }

      // BILL DISCOUNT
      if (discountBills?.length > 0) {
        discountBills.map(x => {
          let amount = 0;
          if (x.pmt_type === Constant.PROMOTION.DISCOUNT.PERCENT) {
            amount = Math.round(remainingSubTotal * x.pmt_type_detail / 100);
            if (amount >= x.limit && x.limit > 0) amount = x.limit;
          }

          if (x.pmt_type === Constant.PROMOTION.DISCOUNT.MONEY) {
            amount = x.pmt_type_detail;
          }

          x.promotionAmount = amount;
          remainingSubTotal -= amount;
        });

        billAmount = discountBills.reduce((prev, cur) => {
          return prev + cur.promotionAmount;
        }, 0);
      }

      let amountDiscountFeeship = 0;
      let amountFeeship = this.getters.hasDeliveryFeeItemV2 ? (state.cart.deliveryFeeItemV2?.baika ?? 0) : 0;
      for (let x of discountFeeships) {
        let amount = 0;
        if (x.pmt_type === Constant.PROMOTION.DISCOUNT.PERCENT) {
          amount = Math.round(amountFeeship * x.pmt_type_detail / 100);
          if (amount >= x.limit && x.limit > 0) amount = x.limit;
        }

        if (x.pmt_type === Constant.PROMOTION.DISCOUNT.MONEY) {
          amount = x.pmt_type_detail;
        }
        amountDiscountFeeship += amount;
        x.promotionAmount = amount;
      }

      // calculate vat for each item
      let lastItem;
      let remainingAmount = billAmount;
      for (const item of state.cart.foods) {
        // if (!item.active_flag) continue; unuse
        lastItem = item;
        let totalPrice = item.totalPrice - item.amountDiscountItem;
        item.amountDiscountBill = Math.round((totalPrice / subTotal) * billAmount);
        remainingAmount -= item.amountDiscountBill;
        item.vatAfterPromotion = commonFunc.getOnlyVAT(totalPrice - item.amountDiscountBill, item.stuckb, item.tax_rate);

        // set to topping
        item.toppings.forEach((tp) => {
          lastItem = tp;
          let totalPrice = tp.totalPrice;
          tp.amountDiscountBill = Math.round((totalPrice / subTotal) * billAmount);
          remainingAmount -= tp.amountDiscountBill;
          tp.vatAfterPromotion = commonFunc.getOnlyVAT(totalPrice - tp.amountDiscountBill, tp.stuckb, tp.tax_rate);
        });
      }

      // prevent lack of amount after rounding
      if (lastItem && remainingAmount > 0) {
        lastItem.amountDiscountBill += remainingAmount;
        let totalPrice = lastItem.totalPrice - lastItem.amountDiscountItem - lastItem.amountDiscountBill;
        lastItem.vatAfterPromotion = commonFunc.getOnlyVAT(totalPrice, lastItem.stuckb, lastItem.tax_rate);
      }

      // VOUCHER
      if (vouchers?.length > 0) {
        vouchers.map(x => { x.promotionAmount = x.pmt_type_detail * x.applyQuantity })
        voucherAmount = vouchers.reduce(function(prev, cur) {
          return prev + cur.promotionAmount;
        }, 0);
      }

      state.cart.coupon = voucherAmount;
      state.cart.amountDiscountFeeship = amountDiscountFeeship;

    },


    calculateCart(state, { isSubmitted = false}) {
      let cart = this.state.cart;
      let foods = cart.foods;
      let count = 0, subTotal = 0, vat = 0;
      let taxRates = {};
      for (const _item of foods) {
        // if (!_item.active_flag) continue;
        count += _item.quantity;
        subTotal += _item.amount;
        vat += _item.vat;
        taxRates[_item.tax_rate] = (taxRates[_item.tax_rate] ?? 0) + _item.vatWithoutTopping;
        for (const _tp of _item.toppings) {
          taxRates[_tp.tax_rate] = (taxRates[_tp.tax_rate] ?? 0) + _tp.vat;
        }
      }

      state.cart.vat = vat;
      state.cart.taxRates = {...taxRates};
      state.cart.subTotal = subTotal;
      state.cart.total = vat + subTotal;
      state.cart.count = count;

      this.commit('calculatePromotion'); // now untill do not apply yet
      subTotal = 0, vat = 0 , taxRates = {};
      for (const _item of foods) {
        // if (!_item.active_flag) continue;
        taxRates[_item.tax_rate] = (taxRates[_item.tax_rate] ?? 0) + (_item.vatAfterPromotion ?? 0);
        subTotal += (_item.amount - _item.amountDiscountItem - _item.amountDiscountBill);
        vat += (_item.vatAfterPromotion ?? 0);
        for (const _tp of _item.toppings) {
          taxRates[_tp.tax_rate] = (taxRates[_tp.tax_rate] ?? 0) + (_tp.vatAfterPromotion ?? 0);
          subTotal += (_tp.amountDiscountItem - _tp.amountDiscountBill);
          vat += (_tp.vatAfterPromotion ?? 0);
        }
      }

      let deliveryFee = this.getters.hasDeliveryFeeItemV2 ? (state.cart.deliveryFeeItemV2?.baika ?? 0) : 0;
      // add promotion feeship
      deliveryFee = Math.max(deliveryFee - state.cart.amountDiscountFeeship, 0);

      state.cart.afterPromotion.subTotal = subTotal;
      let total = subTotal + vat + deliveryFee - state.cart.coupon;
      state.cart.afterPromotion.total = total < 0 ? 0 : total;
      state.cart.afterPromotion.vat = vat;
      state.cart.afterPromotion.taxRates = taxRates;

      if (state.cart.count > 0) {
        state.cart.showCart = true
        state.cart.status = Constant.STATUS_ORDERING
      }

      if (isSubmitted) {
        this.dispatch('updateCart');
      }
    },
  },
  actions: {
    async updateCart({state}) {

      let dataPos = {
        cart: state.cart,
        order_id: this.deliveryId,
      };

      let self = this;

      dataPos.info.status = this.cartCount == 0 ? Constant.STATUS_ORDER_START : Constant.STATUS_ORDERING;

      OrderAPI.updateCart(dataPos)
        .then(() => {
          // self.$store.commit('cartUpdateDeliveryInfo', dataPos.info);
        })
        .catch((err) => {
          self.$gHandleError(
            self.$t('handleError.loadMenuPizzaError'),
            self.$t('handleError.titleError')
          );
          console.log(err);
        });
    },

  }
});
