<template lang="pug">
section.page-full-height
  .mx-0.row
    .col-12.px-md-5.px-sm-2
      .col-12.pt-3
        .section-header-left.pb-3
          h3.text-light-black.header-title.fw-700 {{ $t("surveyFoodList.titlePage") }}
        .u-line.mb-xl-20(v-if="questionFood")
          h3.question-1.mb-2 {{ $t("surveyFoodList.titleQ1") }}
            | {{ questionFood.content }}
          survey-food-item-list(:class-name="'overflow-auto'", :foods="foods", @onTapThumb="onTapThumbFoods")
          h3.question-2.my-2(v-if="resultSurveyService")
            | {{ $t("surveyFoodList.titleQ2") }}
            | {{ resultSurveyService.question.content }}
          thumb-item-list(:type="'q2'", :data="resultSurveyService.result", @onTapThumb="onTapThumbQ2")
          h3.question-2.my-2(v-if="suggestSurveyImprove")
            | {{ $t("surveyFoodList.titleQ3") }}
            | {{ suggestSurveyImprove.question.content }}
          b-form-textarea(v-model="feedback", :placeholder="$t('surveyFoodList.placeholderFeedback')", rows="3", max-rows="6")
          .my-2.d-flex.justify-content-end
            b-button(size="lg", variant="success", @click="onSubmit")
              | {{ $t("surveyFoodList.buttonSubmit") }}
        .w-100.mx-0.row(v-if="isShowEmpty")
          .col-12.px-0.font-size-h3.text-center.text-primary {{ $t("surveyFoodList.empty") }}
  .position-fixed.btn-back-to-menu
    b-button(variant="success", @click="$router.back()")
      b-icon(icon="arrow-left-square", aria-hidden="true")

      | {{ $t("button.back") }}
  Loader(v-show="loading")

</template>

<script>
import OrderAPI from '@/api/order';
import OrtherAPI from '@/api/others';
import { mapGetters } from 'vuex';
import Loader from '@/components/Loader';
import SurveyFoodItemList from '@/components/common/SurveyFoodItemList.vue';
import ThumbItemList from '@/components/common/ThumbItemList.vue';

export default {
  name: 'SurveyFoodList',
  components: {
    Loader,
    SurveyFoodItemList,
    ThumbItemList,
  },
  data: function () {
    return {
      foods: [],
      questionFood: null,
      resultSurveyImprove: null,
      resultSurveyService: null,
      weightSurveyFoods: null,
      stateInterval: null,
      loading: false,
      feedback: null,
      surveyImproveIndex: 0,
      lockedButton: false,
      isShowEmpty: false,
      suggestSurveyImprove: null,
    };
  },
  computed: {
    ...mapGetters(['myCartInfo', 'language', 'currentDeliveryId']),
  },
  watch: {
    language() {
      this.getItemsByTable();
    },
    feedback(newVal, oldVal) {
      if (newVal != oldVal) {
        this.resultSurveyImprove[this.surveyImproveIndex].result.feedback =
          newVal;
      }
    },
  },
  mounted() {
    this.getItemsByTable();
  },

  methods: {
    updateSuggest () {
      if (this.resultSurveyImprove) {
        const {
          lookLike,
          lookDislike,
          tasteLike,
          tasteDislike,
          servingTimeLike,
          servingTimeDislike,
          priceLike,
          priceDislike,
        } = this.weightSurveyFoods;
        const typeSurveys = ['look', 'taste', 'serving_time', 'price'];
        const weightLikes = [
          lookLike,
          tasteLike,
          servingTimeLike,
          priceLike,
        ];
        const weightDisikes = [
          lookDislike,
          tasteDislike,
          servingTimeDislike,
          priceDislike,
        ];

        let calcWeight = {};
        for (const survey of this.foods) {
          for (const [x, type] of typeSurveys.entries()) {
            calcWeight[type] = calcWeight[type] || 0;
            switch (survey[type]) {
            case 1:
              calcWeight[type] += weightLikes[x];
              break;

            case -1:
              calcWeight[type] += weightDisikes[x];
              break;

            default:
              break;
            }
          }
        }

        let limits = weightDisikes.reduce(
          function (limits, element, index, array) {
            limits.max = element > array[limits.max] ? index : limits.max;
            limits.min = element < array[limits.min] ? index : limits.min;
            return limits;
          },
          { max: 0, min: 0 }
        );

        let typeIsBad = typeSurveys[limits.min];
        let min = calcWeight[typeIsBad];
        for (const key in calcWeight) {
          const value = calcWeight[key];
          if (value < min) {
            min = value;
            typeIsBad = key;
          }
        }
        const questionForSurBadIndex = typeSurveys.indexOf(typeIsBad);
        this.surveyImproveIndex = questionForSurBadIndex;
        if (this.feedback == null) {
          this.feedback =
            this.resultSurveyImprove[questionForSurBadIndex].result.feedback;
        } else {
          this.resultSurveyImprove[questionForSurBadIndex].result.feedback =
            this.feedback;
        }
        this.suggestSurveyImprove = this.resultSurveyImprove[questionForSurBadIndex];
      } else {
        this.surveyImproveIndex = -1;
        this.suggestSurveyImprove = null;
      }
    },
    /**
     * Get delivery info
     */
    getItemsByTable() {
      if (!this.myCartInfo.tableNo) return;
      this.loading = true;

      let params = {
        tableNo: this.myCartInfo.tableNo,
        storeId: this.myCartInfo.storeId,
        language: this.language,
      };

      OrderAPI.getSurveyFoods(params)
        .then((res) => {
          let order = res.data.order;
          if (order?.foods?.length ?? false) {
            this.foods = order.foods;
            this.questionFood = order.food_question;
            this.resultSurveyImprove = order.survey_improve.sort(
              (a, b) => Number(a.display_order) - Number(b.display_order)
            );
            this.resultSurveyService = order.survey_service;
            this.weightSurveyFoods = order.weight_survey_food;
            this.updateSuggest();
            this.isShowEmpty = false;
          } else {
            this.isShowEmpty = true;
          }
        })
        .catch((err) => {
          let self = this;
          this.$gHandleError(
            self.$t('handleError.loadDeliveryInforError'),
            self.$t('handleError.titleError')
          );
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onTapThumbFoods({ data }) {
      // console.log(data);
      this.foods[data.index] = data;
      this.updateSuggest();
    },

    onTapThumbQ2({ data }) {
      console.log(data);
      this.resultSurveyService.result = data;
      this.updateSuggest();
    },

    onSubmit() {
      if (this.lockedButton) return;
      this.lockedButton = true;
      this.loading = true;

      // create data to update
      let resultSurveyList = [];

      // Q1
      this.foods.forEach((e) => {
        resultSurveyList.push({
          ...e,
          question_id: this.questionFood.id,
          store_code: this.questionFood.store_code,
          table_code: this.myCartInfo.tableNo,
          table_name: this.myCartInfo.tableName,
          tencd: this.myCartInfo.storeCode,
        });
      });
      // Q2
      resultSurveyList.push({
        ...this.resultSurveyService.result,
        question_id: this.resultSurveyService.question.id,
        store_code: this.resultSurveyService.question.store_code,
        table_code: this.myCartInfo.tableNo,
        table_name: this.myCartInfo.tableName,
        tencd: this.myCartInfo.storeCode,
      });

      // Q3
      resultSurveyList.push({
        ...this.resultSurveyImprove[this.surveyImproveIndex].result,
        question_id: this.resultSurveyImprove[this.surveyImproveIndex].question.id,
        store_code: this.resultSurveyImprove[this.surveyImproveIndex].question.store_code,
        table_code: this.myCartInfo.tableNo,
        table_name: this.myCartInfo.tableName,
        tencd: this.myCartInfo.storeCode,
      });

      let params = {
        tableNo: this.myCartInfo.tableNo,
        storeId: this.myCartInfo.storeId,
        resultSurveyList: resultSurveyList,
      };
      OrtherAPI.submitSurveyFoods(params)
        .then(() => {
          this.lockedButton = false;
        })
        .catch(() => {
          this.$gHandleError(
            this.$t('handleError.loadDeliveryInforError'),
            this.$t('handleError.titleError')
          );
        })
        .finally(() => {
          this.lockedButton = false;
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/views/StatusOrder.scss";
</style>
