<template lang="pug">
div(class="section-padding qr__code__page")
  img.bg-img.d-none.d-sm-block(src="@/assets/images/delivery-bg.jpg")
  img.bg-img.d-block.d-sm-none(src="@/assets/images/delivery-bg-sm.jpg")
  .error(v-if="error")
    b-icon(icon="exclamation-circle-fill", variant="danger")
    .message {{ $t(`byod.qrScanner.error.${error}`) }}
  .camera(v-if="showCamera && !error")
    qrcode-stream(class="qr__wrapper", @init="onInit", @decode="onDecode")
    div(class="camera__info") {{ $t(`byod.pleaseScanQRCode`) }}

</template>


<script>
import { QrcodeStream } from 'vue-qrcode-reader';
export default {
  components: {
    QrcodeStream,
  },
  data: function () {
    return {
      showCamera: true,
      error: null,
    };
  },
  methods: {
    onDecode: function (url) {
      if (url) {
        console.log(url);
        window.location.href = url;
      }
    },
    async onInit(promise) {
      try {
        const { capabilities } = await promise;
      } catch (error) {
        this.error = error.name;
        console.log(error);
      } finally {
        //Exp: hide loading indicator
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-img {
    position: fixed;
    text-align: center;
    width: 100%;
    height: 100%;
    display: block;
}
.error {
    position: relative;
}
.qr__code__page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding-top: 60px;
    .camera {
        width: 400px;
        height: 400px;

        &__info {
            margin-top: 1.5rem;
            position: relative;
            color: white;
            font-size: 1.6rem;
        }
    }
    .qr__wrapper {
        padding: 1rem;
        background: white;
        border-radius: 1rem;
    }
    .camera__info {
        text-align: center;
    }
}

@media (max-width: 575px) {
    .camera {
        width: 250px !important;
        height: 250px !important;
    }
}
</style>
