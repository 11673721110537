<template lang="pug">
.d-flex.my-2.justify-content-end.font-size-h6
  .group-survey.mr-2.d-flex.flex-column
    span {{ $t("surveyFoodList." + label + ".look") }}
    b-button-group.mr-1
      b-button(size="md", :variant="colorThumbUp('look')", @click="onTapThumb(1, 'look')")
        b-icon(icon="hand-thumbs-up", :aria-label="$t('surveyFoodList.' + label + '.look')")
      b-button(size="md", :variant="colorThumbDown('look')", @click="onTapThumb(-1, 'look')")
        b-icon(icon="hand-thumbs-down", :aria-label="$t('surveyFoodList.' + label + '.look')")
  .group-survey.mr-2.d-flex.flex-column
    span {{ $t("surveyFoodList." + label + ".taste") }}
    b-button-group.mr-1
      b-button(size="md", :variant="colorThumbUp('taste')", @click="onTapThumb(1, 'taste')")
        b-icon(icon="hand-thumbs-up", :aria-label="$t('surveyFoodList.' + label + '.taste')")
      b-button(size="md", :variant="colorThumbDown('taste')", @click="onTapThumb(-1, 'taste')")
        b-icon(icon="hand-thumbs-down", :aria-label="$t('surveyFoodList.' + label + '.taste')")
  .group-survey.mr-2.d-flex.flex-column
    span {{ $t("surveyFoodList." + label + ".serve") }}
    b-button-group.mr-1
      b-button(size="md", :variant="colorThumbUp('serving_time')", @click="onTapThumb(1, 'serving_time')")
        b-icon(icon="hand-thumbs-up", :aria-label="$t('surveyFoodList.' + label + '.serve')")
      b-button(size="md", :variant="colorThumbDown('serving_time')", @click="onTapThumb(-1, 'serving_time')")
        b-icon(icon="hand-thumbs-down", :aria-label="$t('surveyFoodList.' + label + '.serve')")
  .group-survey.mr-2.d-flex.flex-column
    span {{ $t("surveyFoodList." + label + ".price") }}
    b-button-group.mr-1
      b-button(size="md", :variant="colorThumbUp('price')", @click="onTapThumb(1, 'price')")
        b-icon(icon="hand-thumbs-up", :aria-label="$t('surveyFoodList.' + label + '.price')")
      b-button(size="md", :variant="colorThumbDown('price')", @click="onTapThumb(-1, 'price')")
        b-icon(icon="hand-thumbs-down", :aria-label="$t('surveyFoodList.' + label + '.price')")

</template>

<script>
export default {
  name: 'ThumbItemList',
  props: {
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'q1',
    },
  },

  data() {
    return {
      cData: this.data,
    };
  },
  computed: {
    label: function () {
      return this.type === 'q1' ? 'answers' : 'answersQ2';
    },
  },
  methods: {
    colorThumbUp(field) {
      if (this.cData[field] == 1) {
        return 'primary';
      }
      return 'secondary';
    },
    colorThumbDown(field) {
      if (this.cData[field] == -1) {
        return 'primary';
      }
      return 'secondary';
    },
    onTapThumb(value, field) {
      let updatedData = { ...this.cData };
      updatedData[field] = value == updatedData[field] ? 0 : value;
      this.$emit('onTapThumb', {
        data: { ...updatedData },
      });

      this.cData = updatedData;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
