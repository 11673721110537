import OtherAPI from '@/api/others';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['tableInfo', 'myCartInfo',]),
  },
  methods: {
    /**
         * Save delivery info
         */
    async checkTableInfo() {
      try {
        let params = {
          tableNo: this.myCartInfo.tableNo,
          storeId: this.myCartInfo.storeId,
          language: this.language,
        };
        const {
          data: { tableInfo: newTableInfo },
        } = await OtherAPI.getTableInfo(params);
        let { kjymd, odseq } = newTableInfo;

        // khác cái bàn rồi
        console.log(kjymd, odseq, this.tableInfo);
        if (this.tableInfo.kjymd != kjymd || this.tableInfo.odseq != odseq) {
          let isOK = await this.$gMsgBoxOk(this.$t('byod.TABLE_CHANGE_INFO'), this.$t('byod.NOTIFY'));
          console.log(`isOK ${isOK}`);
          this.$store.commit('cartUpdateDeliveryInfo', {
            ...this.myCartInfo,
            tableNo: newTableInfo.tableNo,
            tableName: newTableInfo.tableName,
            storeCode: newTableInfo.storeCode,
            storeId: newTableInfo.storeId,
            brandCode: newTableInfo.brandCode,
            kjymd: newTableInfo.kjymd,
            odseq: newTableInfo.odseq,
          });

          return false;
        }
        return true;
      } catch (error) {
        console.log(error);
        this.$gMsgBoxOk(this.$t(`byod.${error?.response?.data?.error_code ?? 'ERROR_OCCUR'}`),
          this.$t('byod.NOTIFY'),
        ).then((isOK) => {
          this.$emit('callStaff');
        });

        return false;
      }
    },
  }
}
