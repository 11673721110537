import session from './session'
import { RootAPI } from './index'

export default {
  /**
   * Call Staff
   */
  callStaff(params) {
    return session.post(RootAPI + 'v2/byod/call-staff/', params);
  },

  submitSurveyFoods(params) {
    return session.post(RootAPI + 'v2/byod/submit-survey-food', params);
  },
  /**
   * Get curent table
   * @param {*} params
   * @returns
   */
  getTableInfo(params) {
    return session.post(RootAPI + 'v2/byod/table-info', params);
  }

}
