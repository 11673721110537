import moment from 'moment'
import Constant from '@/common/constant'

export default {
  /**
   * Validate image link
   * @param {*} imageLink
   */
  isValidImageLink(imageLink) {
    // let regex = /(http(s?):)|([/|.|\w|\s])*\.(?:jpg|gif|png|jpeg)/
    return (/\.(gif|jpe?g|tiff|png|webp|bmp)$/i).test(imageLink)
  },
  /**
   * Validate email address
   * @param {*} email
   */
  validateEmailAddress (email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  /**
   * Validate phone number
   */
  isValidPhoneNumber(phone) {
    var re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return re.test(phone);
    // console.lo,g(regex.test(phone))
  },

  /**
   * Interger only
   */
  intergerOnly (valueInput) {
    if (valueInput != '') {
      valueInput = valueInput.replace(/[^0-9-]+/g,'');
    }
    return valueInput
  },

  formatStrToDate(strDate) {
    if (strDate === null || strDate === '') {
      return;
    }
    let yyyy = strDate.substring(0, 4);
    let mm = strDate.substring(4, 6);
    let dd = strDate.substring(6, 8);
    return yyyy + '-' + mm + '-' + dd;
  },

  getPriceNonVAT(price, stuckb, tax=10) {
    price = parseInt(price, 10);
    switch (parseInt(stuckb, 10)) {
    case 0:
      return price;
    case 1:
      return price;
    case 2:
      return Math.round(price / ((100 + tax)/100));
    default:
      return price;
    }
  },

  getOnlyVAT(price, stuckb, tax=10) {
    switch (parseInt(stuckb, 10)) {
    case 0:
      return 0;
    case 1:
      return Math.round(price * (tax/100));
    case 2:
      return Math.round(price - price/((100 + tax)/100));
    default:
      return Math.round(price * (tax/100));
    }
  },

  getPriceIncludingVAT(price, stuckb, tax=10) {
    switch(parseInt(stuckb, 10)) {
    case 0:
      return price;
    case 1:
      return Math.round(price * ((100 + tax)/100));
    case 2:
      return price;
    default:
      return price;
    }
  },


  // sotozei = VAT of items with stuckb = 1
  getSotozei(price, quantity, stuckb, tax=10) {
    if (parseInt(stuckb, 10) !== 1) return 0;

    const priceSum = parseInt(price, 10) * parseInt(quantity, 10);
    return Math.round(priceSum *(tax/100));
  },

  // uchizei = VAT of items with stuckb = 2
  getUchizei(price, quantity, stuckb, tax=10) {
    if (parseInt(stuckb, 10) !== 2) return 0;

    const priceSum = parseInt(price, 10) * parseInt(quantity, 10);
    return Math.round(priceSum - (priceSum/((100 + tax)/100)));
  },

  arrayDiff(exclude, full) {
    const setExclude = new Set(exclude);
    const differenceSet = new Set(full.filter(x => !setExclude.has(x)));
    return [...differenceSet ];
  },


  formatDateFull(value){
    if(value==undefined || value== '') return ''
    return moment(String(value)).format('YYYY-MM-DD HH:mm:ss')
  },
  formatToOrderTimeNumber(value){
    if(value==undefined || value== '') return ''
    return Number(moment(String(value)).format('HHmm'))
  },

}
