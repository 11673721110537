import axios from 'axios'
import Constants from '@/common/constant';
// import Cookies from 'js-cookie'
// import { Constant } from '../common/constant'

// const TOKEN_NAME = Constant.TOKEN_NAME
// const TOKEN_TEMP_NAME = Constant.TOKEN_TEMP_NAME
const CSRF_COOKIE_NAME = 'csrftoken'
const CSRF_HEADER_NAME = 'X-CSRFToken'

const session = axios.create({
  xsrfCookieName: CSRF_COOKIE_NAME,
  xsrfHeaderName: CSRF_HEADER_NAME,
  headers: {Authorization: 'Bearer ' + Constants.TOKEN_PRO_MAX},
  timeout: 60000
})

export default session
