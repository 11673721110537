<template lang="pug">
#app.bg-light-white
  .header
    header.full-width
      .container-fluid.verticalCenter
        .w-100
          .col.px-0.mainNavCol
            .logo.mainNavCol
              a.brand(href="/") {{ $t("app.delivery") }}
            .main-search.mainNavCol
            .fw-600.mainNavCol
              //- .ml-3(v-if="hideShoppingBag")
              //-   b-nav-item.px-0(to="/check-bill")
              //-     p.h4.mt-2
              //-       b-icon(icon="receipt")
              .ml-3(v-if="hideShoppingBag")
                b-nav-item.px-0(to="/delivery-status")
                  p.h4.mt-2
                    b-icon(icon="list-check")
              .cart-btn.mr-3.ml-3(v-if="hideShoppingBag")
                b-nav-item.px-0.position-relative(to="/my-cart")
                  span.user-alert-cart {{ cartCount }}
                  b-icon(icon="cart")
              .user-details.p-relative
                .toggle-btn-group
                  input#toggle-on.toggle.toggle-left(name="toggle", type="radio", value="false", :checked="language == 'en' || language == null", @click="switchLanguage('en')")
                  label.toggle-btn.no-margin.no-padding(for="toggle-on", :class="language == 'en' ? 'active' : ''") EN
                  input#toggle-off.toggle.toggle-right(name="toggle", type="radio", value="true", :checked="language == 'vn' || language == null", @click="switchLanguage('vn')")
                  label.toggle-btn.no-margin.no-padding(for="toggle-off", :class="language == 'vn' ? 'active' : ''") VN
  keep-alive(include="MainMenu")
    router-view(@callStaff="callStaff")
  .h2.mb-0.position-fixed.at-table(v-if="hideShoppingBag")
    b-icon(icon="signpost", variant="primary", scale="1")

    | {{ this.myCartInfo.tableName }}
  .h2.mb-0.position-fixed.call-staff-telephone(v-if="hideButtonCstaff", @click="callStaff")
    b-iconstack(font-scale="1.5", animation="throb")
      b-icon(stacked, icon="telephone", scale="0.75")
      b-icon(stacked, icon="circle", variant="primary", scale="1.25")
  b-toast#my-toast(variant="warning", solid)
    template(#toast-title)
      .d-flex.flex-grow-1.align-items-baseline.toast-title
        b-img.mr-2(blank, blank-color="#ff5555", width="12", height="12")
        strong.mr-auto {{ $t("commons.callStaff") }}
    .toast-body {{ $t("message.caffStaff") }}

</template>

<script>
import { mapGetters } from 'vuex';
import OthersAPI from '@/api/others';

export default {
  name: 'App',
  data() {
    return {
      isCartScreen: false,
      isShow: false,
      publicPath: process.env.BASE_URL,
      disableCallStaff: false,
    };
  },
  computed: {
    ...mapGetters(['cartCount', 'myCartInfo', 'language']),

    hideShoppingBag() {
      return !['QRInput', 'DecodeToken'].includes(this.$route.name);
    },

    hideButtonCstaff() {
      return !['QRInput'].includes(this.$route.name);
    },
  },
  methods: {
    openCart() {
      if (this.isCartScreen) {
        this.$router.push('/my-cart/').catch(() => {});
      } else {
        this.$router.push('/main-menu/').catch(() => {});
      }
    },
    switchLanguage(lang) {
      if (lang != this.language) {
        this.$i18n.locale = lang;
        this.$store.commit('setLanguage', lang);
      }
    },
    callStaff() {
      if (this.disableCallStaff) {
        return;
      }
      this.disableCallStaff = true;
      OthersAPI.callStaff({
        storeId: this.myCartInfo.storeId,
        storeCode: this.myCartInfo.storeCode,
        tableNo: this.myCartInfo.tableNo,
        tableName: this.myCartInfo.tableName,
        featureCode: `byod${this.$route.name}`
      })
        .then(() => {
          this.$bvToast.show('my-toast');
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.disableCallStaff = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");
@import "@/assets/styles/views/App.scss";
</style>
