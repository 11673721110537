import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import MenuToppingOption from '../views/MenuToppingOption.vue'
import QRInput from '../views/QRInput.vue'
import MainMenu from '../views/MainMenu.vue'
import MyCart from '../views/MyCart.vue'
import ConfirmOrder from '../views/ConfirmOrder.vue'
import StatusOrder from '../views/StatusOrder.vue'
import CheckBill from '../views/CheckBill.vue'
import SurveyFoodList from '../views/SurveyFoodList.vue'
import DecodeToken from '../views/DecodeToken.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'QRInput',
    component: QRInput
  },
  {
    path: '/detail',
    name: 'MenuToppingOption',
    component: MenuToppingOption
  },
  {
    path: '/main-menu',
    name: 'MainMenu',
    component: MainMenu,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/my-cart',
    name: 'MyCart',
    component: MyCart,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/confirm-order',
    name: 'ConfirmOrder',
    component: ConfirmOrder
  },
  {
    path: '/delivery-status',
    name: 'StatusOrder',
    component: StatusOrder
  },
  {
    path: '/survey-foods',
    name: 'SurveyFoodList',
    component: SurveyFoodList
  },
  {
    path: '/check-bill',
    name: 'CheckBill',
    component: CheckBill
  },
  {
    path: '/:token',
    name: 'DecodeToken',
    component: DecodeToken
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
