<template lang="pug">
section.page-full-height
  .row {{ numitems = 0 }}
  .mx-0.row
    div(class="col-12 main__menu")
      .menu-category
        .inner.w-100
          b-button-group(v-if="dataLoaded.category")
            b-button.btn-category(v-for="(category, index) in categoryList", :id="category.bumoncd", :key="category.bumoncd", :pressed="categoryStates[index].state", :tabindex="numitems+=1", @click="setCategoryAndClass(index, $event);") {{ category.bumonnm }}
      hr
      .menu-class(v-if="dataLoaded.class")
        b-button-group.w-100(v-if="classListDisplay.length > 1")
          b-button.btn-class(v-for="(klass, index) of classListDisplay", :id="klass.bunruicd", :key="klass.bunruicd", :pressed="tracker['category_' + current.categoryIndex][index]['state']", :bunruinm="klass.bunruinm", :tabindex="numitems+=1", @click="setClassAndMenu(index, $event)") {{ klass.bunruinm }}
      .menu-item-container
        .alert.alert-danger(v-if="alertMessage") {{ $(alertMessage) }}
        div(v-if="menuItems.length>0")
          .form-row.d-none.d-md-flex
            div(v-for="(menu, menuIndex) of menuItems", :key="menu.menucd + 'menuI' + menuIndex", :class="menu.menucd ? '' : 'leftover'", class="col-md-4 col-xl-4 mb-4 card__clickable")
              menu-item(:menu="menu", :clickable="true")
          .form-row.d-flex.d-md-none
            .col-md-4.col-xl-4.mb-4(v-for="(menu, menuIndex) of menuItems", :key="menu.menucd + numitems + 'menuI' + menuIndex", :class="menu.menucd ? '' : 'leftover'")
              menu-item(:menu="menu", :clickable="false")
        div(v-else, style="text-align: center")
          h5(v-if="menuItems.length==0 && !loading") {{ $t(&quot;commons.soldOut&quot;) }}
  Loader(v-show="loading")

</template>
<script>
import { mapGetters } from 'vuex';
import MenuItem from '@/components/common/MenuItem.vue';
import Loader from '@/components/Loader';

import mainMenuMixins from '../mixins/mainMenu.js'

export default {
  name: 'MainMenu',
  components: {
    Loader,
    MenuItem,
  },
  mixins: [mainMenuMixins],
  data() {
    return {
      hover: false,
      isFirstLoad: true,
      theme: 'light',
      loading: false,
      alertMessage: null,
      current: {
        categoryIndex: 0,
        categoryId: null,
        classIndex: 0,
        classId: null,
      },
      dataLoaded: {
        category: false,
        class: false,
        menu: false,
      },
      // Keep track of selected [Category - Class]
      tracker: {},

      // Original Class data. Load on init
      categoryList: null,

      // Set pressed state for category buttons
      categoryStates: [],

      // Original Class data. Load on init
      classList: null,

      // Class to be displayed based on category
      classListDisplay: [],

      // Number of menu row. Dynamically changes when a new [Category - Class] is selected
      menuRow: null,

      // Original menu data. Dynamically changes when a new [Category - Class] is selected
      menuList: [],

      // Menu list. Filtered by chosen language.
      menuListByLanguage: [],

      // Menu to be displayed. Divided by rows
      menuDisplay: {},
    };
  },
  computed: {
    menuListLength() {
      return this.menuList.length;
    },
    menuItems: function () {
      const items = [];
      for (let row in this.menuDisplay) {
        this.menuDisplay[row].forEach((el) => items.push(el));
      }
      return items;
    },
    ...mapGetters(['cartCount', 'myCartInfo', 'currentDeliveryId', 'language']),
  },
  watch: {
    language(val) {
      this.getMenu();
    },
  },

  created() {
    if (!this.currentDeliveryId) {
      this.$router.push('/');
    }
    this.loading = true;
    this.getCategory();
  },

  mounted() {
    // this.$root.$on('saveDeliveryInfo', () => {
    //   this.saveDeliveryInfo()
    // });
    this.checkTableInfo();
  },

  destroyed() {
    this.isFirstLoad = false;
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/main.scss";
@import "@/assets/styles/views/MainMenu.scss";
</style>
