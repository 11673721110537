<template>
  <div class="loader">
    <div class="loader__backdrop">
      <div class="loader__content">
        <div class="loader__spinner"></div>
        <div v-if="message" class="loader__message">{{$t(message)}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: ['message']
}
</script>

<style lang="scss">
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  &__backdrop {
    background-color: rgba($color: #000000, $alpha: 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

  &__spinner {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-color: #20a8d8;
    border-width: 5px 5px 0px 0;
    border-style: solid;
    margin-bottom: 15px;
    animation: spin 1s linear infinite;
  }

  &__message {
      color: #20a8d8;
      text-align: center;
  }
}
</style>