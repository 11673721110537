<template lang="pug">
.row.px-2
  .col-lg-12.py-1.border.border-top-0.border-left-0.border-right-0.border-secondary(v-for="(item, x) of foods", :key="item.menucd")
    .checkout-product(v-if="item.isHalf")
      .flex-column
        .img-name-value
          .product-img
            a
              img(:src="item.left.image", alt="#")
          .product-value
            span.text-light-white {{ item.left.quantity }}
          div(:title="item.left.name")
            span
              a.text-light-white.product-name
                | {{ item.left.name }}
            br
            template(v-for="(option, y) of item.left.options")
              div(:key="`${x}` + `${y}`")
                span.text-light-white.fw-700.product-topping
                  | {{  option.name  }}
                br
        .img-name-value
          .product-img
            a
              img(:src="item.right.image", alt="#")
          .product-value
            span.text-light-white {{ item.right.quantity }}
          div(:title="item.right.name")
            span
              a.text-light-white.product-name
                | {{ item.right.name }}
            br
            template(v-for="(option, y) of item.right.options")
              div(:key="`${x}` + `${y}`")
                span.text-light-white.fw-700.product-topping
                  | {{ option.name }}
                br
      .product-price.d-flex
        span.text-light-white
          | {{ item.totalWOption | formatCurrency }}
        .product-status-image.ml-1(v-if="showStatus")
          b-img(v-if="item.status == 'Cooking'", v-bind="mainProps", :src="require('@/assets/images/icon_cooking.png')", rounded="circle", alt="Circle image")
          b-img(v-if="item.status == 'Serve'", v-bind="mainProps", :src="require('@/assets/images/icon_serve.png')", rounded="circle", alt="Circle image")
    .checkout-product(v-else)
      .img-name-value
        .product-img
          a
            img(:src="item.image", alt="#")
        .product-value
          span.text-light-white {{ item.quantity }}
        div(:title="item.name")
          span
            a.text-light-white.product-name {{ item.name }}
          br
          template(v-for="(option, y) of item.options")
            div(:key="`${x}` + `${y}`")
              span.text-light-white.fw-700.product-topping
                | {{ option.name }}
              br
      .product-price.d-flex
        span.text-light-white
          | {{ item.total_w_option | formatCurrency }}
        .product-status-image.ml-1(v-if="showStatus")
          b-img(v-if="item.status == 'Cooking'", v-bind="mainProps", :src="require('@/assets/images/icon_cooking.png')", rounded="circle", alt="Circle image")
          b-img(v-if="item.status == 'Serve'", v-bind="mainProps", :src="require('@/assets/images/icon_serve.png')", rounded="circle", alt="Circle image")

</template>

<script>
export default {
  name: 'PosItemList',
  props: {
    foods: {
      type: Array,
      required: true,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mainProps: { width: 40, height: 40, class: 'm1' },
    };
  },
};
</script>
<style lang="scss" scoped>
.product-status-image {
  width: 40px;
}
</style>
