<template lang="pug">
section.page-full-height
  .mx-0.row
    .col-12.px-md-5.px-sm-2
      .col-12.pt-3
        .section-header-left
          h3.text-light-black.header-title.fw-700 {{ $t("deliveryStatus.titlePage") }}
        .u-line.mb-xl-20
          pos-item-list(:foods="foods", :show-status="true")
        .w-100.mx-0.row(v-if="foods.length > 0")
          .col-12.px-0
            .price-table.u-line
              .item
                span.text-light-white {{ $t("deliveryInfo.subTotal") }}
                  | :
                span.text-light-white
                  | {{ info.subTotal | formatCurrency }}
              .item(
                v-for="(v,k,i) in info.taxRates"
              )
                span.text-light-white {{ $t("deliveryInfo.vat") }} {{ k }}%
                  | :
                span.text-light-white
                  | {{ v | formatCurrency }}
            .total-price.padding-tb-10
              h5.title.text-light-black.fw-700 {{ $t("deliveryInfo.total") }}

                span {{ info.total | formatCurrency }}
        .w-100.mx-0.row(v-if="isShowEmpty")
          .col-12.px-0.font-size-h3.text-center.text-primary {{ $t("checkBill.empty") }}
  .position-fixed.btn-back-to-menu
    b-link(v-if="!loading", to="main-menu")
      b-button(variant="success")
        b-icon(icon="arrow-left-square", aria-hidden="true", class="mr-2")
        | {{ $t("cart.backToMenu") }}
    //- b-link.ml-1(v-if="!loading", to="survey-foods")
    //-   b-button(variant="success")
    //-     i.fas.fa-poll-h.mr-2
    //-     | {{ $t("button.survey") }}

  Loader(v-show="loading")

</template>

<script>
import OrderAPI from '@/api/order';
import { mapGetters } from 'vuex';
import Loader from '@/components/Loader';
import PosItemList from '@/components/common/PosItemList.vue';

export default {
  name: 'StatusOrder',
  components: {
    Loader,
    PosItemList,
  },
  data: function () {
    return {
      info: {
        subTotal: 0,
        total: 0,
        vat: 0,
        taxRates: {},
      },
      foods: [],
      loading: false,
      isShowEmpty: false,
    };
  },
  computed: {
    ...mapGetters(['myCartInfo', 'language', 'getOldDeliverys']),
  },
  mounted() {
    this.getFoodsAtStore();
  },
  methods: {
    /**
     * Get delivery info
     */
    getFoodsAtStore() {
      if (!this.myCartInfo.tableNo) return;
      this.loading = true;

      let params = {
        tableNo: this.myCartInfo.tableNo,
        storeId: this.myCartInfo.storeId,
        language: this.language,
      };

      OrderAPI.getStatusOrder(params)
        .then((res) => {
          let order = res.data.order;
          if (order?.foods?.length ?? false) {
            this.foods = order.foods;
            this.info.subTotal = order.total_wo_vat;
            this.info.total = order.total_w_vat;
            this.info.vat = order.positive_vat;
            this.info.taxRates = order.tax_rates;
            this.isShowEmpty = false;
          } else {
            this.foods = [];
            this.info.subTotal = 0;
            this.info.total = 0;
            this.info.vat = 0;
            this.info.taxRates = {};
            this.isShowEmpty = true;
          }
        })
        .catch((err) => {
          this.$gHandleError(
            this.$t('handleError.loadDeliveryInforError'),
            this.$t('handleError.titleError')
          );
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/views/StatusOrder.scss";
</style>
