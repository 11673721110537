<template lang="pug">
div(class="page-full-height decode__page")
  .wrapper
    Loader(v-if="loading", :message="loadingMessage")
    .error.d-flex.justify-content-center.align-items-center.flex-column(v-if="error")
      b-icon(icon="exclamation-circle-fill", variant="danger")
      .message {{ $t(error) }}
      button.btn.btn-primary(@click="toHomePage()") {{ $t("byod.RESCAN_QRCODE") }}

</template>

<script>
import { mapGetters } from 'vuex';
import Loader from '@/components/Loader';
import byodAPI from '@/api/byod';
import cartMixin from '@/mixins/cart';

export default {
  name: 'DecodeToken',
  components: {
    Loader,
  },
  mixins: [cartMixin],
  data() {
    return {
      loading: false,
      error: null,
      loadingMessage: 'byod.CHECKING_TOKEN',
    };
  },

  computed: {
    ...mapGetters(['myCartInfo', 'language']),
  },
  mounted: async function () {
    const { token } = this.$route.params;
    this.loading = true;
    if (!token) {
      this.loading = false;
      this.error = 'byod.ERROR_OCCUR';
    } else {
      try {
        const {
          data: { order_info },
        } = await byodAPI.decode(token);
        let { storeCode, storeId, tableNo, orderNo, tableName, brandId, brandCode, kjymd, odseq} =
          order_info;
        this.$store.commit('cartUpdateDeliveryInfo', {
          ...this.myCartInfo,
          orderNo,
          tableNo,
          tableName,
          storeCode,
          storeId,
          brandCode,
          brandId,
          kjymd,
          odseq,
        });

        let rs = await this.createCart();

        if (rs) {
          this.$router.push('/main-menu/').catch(() => {});
        } else {
          this.error = 'byod.ERROR_OCCUR';
        }
      } catch (error) {
        this.error = `byod.${error?.response?.data?.error_code ?? 'ERROR_OCCUR'}`;
      }
      this.loading = false;
    }
  },

  methods: {
    toHomePage() {
      this.$router.push('/').catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.decode__page .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .error {
    svg {
      width: 150px;
      height: 150px;
      margin-bottom: 15px;
    }

    .message {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 15px;
    }
  }
}
</style>
