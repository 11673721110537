<template lang="pug">
div(class="card__info card__info-right col-2 d-none d-md-block")
  .sidebar.main-box.d-none.d-lg-block
    .cart-detail-box
      .card
        .card-header.text-center.padding-10.fw-700
          h5.fw-600 {{ $t("deliveryInfo.YourOrder") }}
        #scrollstyle-4.card-body
          h6.mt-2.text-center.mb-4(v-if="cartCount === 0") {{ $t("cart.empty") }}
          .wrapper
            div(v-for="(item) of myCartInfo.foods", :key="item.menuid")
              .cat-product-box
                .cat-product-item(:title="item.menunm")
                  .cat-name
                    p.text-light-black.fw-700
                      span.text-dark-white {{ item.quantity }}

                      | {{ item.menunm }}
                    .text-light-white.fw-500
                      p {{ menuChosenToppings(item.menuid) }}
                  .price
                    a.fw-500 {{ item.priceWithTopping | formatCurrency }}
          hr
          .item-total
            .total-price.border-0
              span.text-black {{ $t("deliveryInfo.subTotal") }}
                | :
              span.text-black {{ myCartInfo.afterPromotion.subTotal | formatCurrency }}
            .total-price.border-0(
              v-for="(v,k,i) in myCartInfo.afterPromotion.taxRates"
            )
              span.text-black {{ $t("deliveryInfo.vat") }} {{k}}%
                | :
              span.text-black {{ v  | formatCurrency }}
            .total-price.border-0.mt-3
              span.text-black.fw-600 {{ $t("deliveryInfo.total") }}
                | :
              span.text-black.fw-600 {{ myCartInfo.afterPromotion.total | formatCurrency }}
        .card-footer(v-if="cartCount>0")
          b-button.btn-first.green-btn.text-custom-white.full-width.fw-500(variant="primary", to="/confirm-order", @click="checkOut()") {{ $t(&apos;button.proceedToCheckout&apos;) }}
        .card-footer.p-0.modify-order(v-show="false")
          a.total-amount(href="#")
            span.text-custom-white.fw-500 {{ $t("deliveryInfo.total") }}
            span.text-custom-white.fw-500 {{ 0 | formatCurrency }}

</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'CartInfo',
  data() {
    return {
      dataTemp: {},
      isShow: true,
    };
  },
  computed: {
    ...mapGetters([
      'cartCount',
      'menuChosenToppings',
    ]),
  },
  methods: {
    checkOut() {
      this.$router.push('/confirm-order');
    },
  },
};
</script>

<style lang="scss" scoped>
.price a {
    color: #000000;
}
.nav-link {
    color: #ffffff;
}
.cat-product-box {
    a {
        cursor: default;
    }
}
.cat-product-item {
    display: block;
    margin: 10px 0px;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .cat-name {
        p {
            font-size: 1.5rem;
            line-height: 1.7rem;
        }
        span {
            font-size: 2rem;
            margin-right: 2px;
        }
        line-height: 0;
    }

    .price {
        margin-top: 1rem;
        text-align: right;
    }

    p {
        margin: 0;
    }

    .item-total {
        margin-top: 20px;
    }
}
.cat-product-item:last-child {
    margin-bottom: 20px;
}

.wrapper {
    max-height: 40vh;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}

.card__info {
    position: fixed !important;
    padding-top: 1.5rem;
    height: 100%;
    &-left {
        left: 0px;
        margin-left: 2rem;
    }
    &-right {
        right: 0px;
        margin-right: 2rem;
        height: calc(100vh - 5rem);
        padding: 1.5rem;
        overflow: hidden;
        z-index: 1;
    }
}

</style>
